import React, { Component } from 'react'
import StorageSidebar from './components/StorageSidebar'
import StorageTable from './components/StorageTable'
import { jobTypes } from '../orders/Order/jobs/jobTypes'
import { withTranslation } from 'react-i18next'
import { fetchApi } from '../../services/api'
import './MainStorage.css'

class MainStorage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableArray: [],
			showAllProducts: true,
			isModalSidebarOpen: false,
			isModalStorageTableOpen: false,
			groupId: 0,
			groupName: '',
			actionName: '',
			productId: 0,
			showProductsByCat: false,
			productsArray: [],
			selectedColumnsArr: [],
			loading: true,
			loadingProducts: true,
			jobTypesArray: []
		}
	}

	columns = [
		{ title: this.props.t('storage.productCode'), field: 'productCode' },
		{
			title: this.props.t('storage.productName'),
			field: 'name',
			defaultSort: 'asc'
		},
		{
			title: this.props.t('storage.productDesc'),
			field: 'additionalInformation',
			cellStyle: {
				maxWidth: 30,
				whitSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		},
		{
			title: this.props.t('storage.location'),
			field: 'shelfLocation',
			filtering: false
		},
		{
			title: this.props.t('storage.unit'),
			field: 'unit',
			filtering: false,
			render: rowData => (
				<div>
					{!!rowData.unit ? (rowData.unit === 'pc' ? this.props.t('orders.pc') : rowData.unit) : ''}
				</div>
			)
		},
		{
			title: this.props.t('storage.available'),
			field: 'inStock',
			filtering: false
		},
		{
			title: this.props.t('storage.reserved'),
			field: 'reserved',
			filtering: false
		},
		{
			title: this.props.t('storage.picture'),
			field: 'picture',
			cellStyle: {
				maxWidth: 30,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			},
			filtering: false
		},
		{
			title: this.props.t('storage.paperSize'),
			field: 'size',
			filtering: false
		},
		{
			title: this.props.t('storage.thickness'),
			field: 'thickness',
			filtering: false,
			render: rowData => (
				<div>{!!rowData.thickness && `${rowData.thickness} g`}</div>
			)
		},
		{
			title: this.props.t('storage.width'),
			field: 'width',
			filtering: false,
			render: rowData => (
				<div>
					{!!rowData.width &&
						(!!rowData.measureUnit
							? `${rowData.width} ${rowData.measureUnit}`
							: `${rowData.width} mm`)}
				</div>
			)
		},
		{
			title: this.props.t('storage.height'),
			field: 'height',
			filtering: false,
			render: rowData => (
				<div>
					{!!rowData.height &&
						(!!rowData.measureUnit
							? `${rowData.height} ${rowData.measureUnit}`
							: `${rowData.height} mm`)}
				</div>
			)
		},
		{
			title: this.props.t('storage.length'),
			field: 'prodLength',
			filtering: false,
			render: rowData => (
				<div>
					{!!rowData.prodLength &&
						(!!rowData.measureUnit
							? `${rowData.prodLength} ${rowData.measureUnit}`
							: `${rowData.prodLength} mm`)}
				</div>
			)
		},
		{
			title: this.props.t('storage.selling'),
			field: 'sellingPrice',
			filtering: false
		},
		{ title: this.props.t('storage.vat'), field: 'vat', filtering: false },
		{
			title: this.props.t('storage.purchase'),
			field: 'purchasePrice',
			filtering: false
		},
		{
			title: this.props.t('storage.wastePrice'),
			field: 'wastePrice',
			filtering: false
		}
	]

	storageSettings = [
		{
			field: 'inventoryMonitoring',
			title: this.props.t('storage.inventoryMonitoring')
		},
		{ field: 'storageUnits', title: this.props.t('storage.storageUnits') }
	]

	componentDidMount() {
		this.createJobsArray()
		this.onLoadCompanyData().then(resolve => {
			if (resolve === true) {
				this.onLoad().then(resolve => {})
			}
		})
	}

	createJobsArray = () => {
		let newJob = {}
		const jobTypesArray = []

		Object.entries(jobTypes).forEach(job => {
			newJob = { field: job[0], title: job[1].title }
			jobTypesArray.push(newJob)
		})
		this.setState({
			jobTypesArray
		})
	}

	onLoadingProducts = () => {
		this.setState({
			loadingProducts: true
		})
	}

	onLoad = async (...categoryStatus) => {
		try {
			const response = await fetchApi('/api/groups/loadGroups', 'POST', {})

			if (response.status === 200) {
				const arr = response.data.data

				if (Array.isArray(arr)) {
					let showAllProducts = true
					let showProductsByCat = false
					let groupId = 0
					let groupName = ''
					if (categoryStatus.includes('deleted')) {
						groupId = 0
						groupName = ''
					} else if (
						categoryStatus.includes('new') ||
						categoryStatus.includes('updated')
					) {
						groupId = categoryStatus[1]
						groupName = categoryStatus[2]
						showProductsByCat = true
						showAllProducts = false
					}
					await new Promise(resolve => {
						this.setState(
							{
								showProductsByCat,
								showAllProducts,
								groupId,
								groupName,
								tableArray: arr,
								loading: false,
								loadingProducts: true
							},
							async () => {
								showAllProducts
									? await this.fillTables()
									: await this.loadProductsByCat(
											groupId,
											groupName,
											categoryStatus
									  )
							}
						)
						resolve(true)
					})
					return true
				} else return null
			}

			if (response.status === 204) {
				return null
			}
		} catch (err) {
			console.error(err.message)
			return false
		}
	}

	onLoadCompanyData = async () => {
		try {
			const response = await fetchApi('/api/v1/companies/companies', 'GET')
			if (response.status === 200) {
				const { data } = response.data
				this.companyVatRates = [...data.vat]
				return true
			}

			if (response.status === 204) {
				return null
			}
		} catch (err) {
			return false
		}
	}

	fillTables = async () => {
		try {
			const response = await fetchApi(
				'/api/products/loadProducts/all',
				'GET',
				{}
			)
			if (response.status === 200) {
				const products = response.data.data
				let allProducts = []
				if (Array.isArray(products) && products.length > 0) {
					const vats = this.companyVatRates
					const map = new Map()
					vats.forEach(value => {
						map.set(value._id, value.value)
					})

					allProducts = products.map(value => {
						const obj = Object.assign({}, value)
						obj.vat = map.get(value.vat)
						return obj
					})
				}

				await new Promise(resolve => {
					this.setState(
						{
							productsArray: allProducts,
							loadingProducts: false
						},
						() => {}
					)
				})

				return true
			}
			if (response.status === 204) {
				return null
			}
		} catch (err) {
			console.error('fillTables', err.message)
			return false
		}
	}

	onModifyCategory = (groupId, actionName) => {
		this.setState({
			isModalSidebarOpen: true,
			groupId: groupId,
			actionName: actionName
		})
	}

	onModifyProduct = (groupId, productId, actionName) => {
		if (Array.isArray(this.state.tableArray)) {
			if (this.state.tableArray.length !== 0) {
				this.setState({
					isModalStorageTableOpen: true,
					groupId: groupId,
					productId: productId,
					actionName: actionName
				})
			} else {
				alert('no groups, empty')
			}
		} else {
			alert('no groups, undefined')
		}
	}

	handleCancelModalCategory = () => {
		this.setState({
			isModalSidebarOpen: false
		})
	}

	modalSidebarClose = () => {
		this.setState({
			isModalSidebarOpen: false
		})
	}

	handleCancelModalProduct = () => {
		this.setState({
			isModalStorageTableOpen: false
		})
	}
	modalStorageTableClose = async () => {
		try {
			this.setState(
				{
					isModalStorageTableOpen: false,
					loadingProducts: true
				},
				async () => {
					if (this.state.showAllProducts) {
						await this.fillTables()
					} else if (this.state.showProductsByCat) {
						await this.loadProductsByCat(
							this.state.groupId,
							this.state.groupName
						)
					}
				}
			)
		} catch (err) {
			console.log(err.message)
			return false
		}
	}

	onShowAllProducts = async () => {
		try {
			this.setState(
				{
					showAllProducts: true,
					showProductsByCat: false,
					groupId: 0,
					loadingProducts: true
				},
				async () => {
					await this.fillTables()
				}
			)
		} catch (err) {
			console.log(err.message)
			return false
		}
	}

	onShowProductsByCat = async (id, selectedColumnsArr, groupName) => {
		this.setState(
			{
				showAllProducts: false,
				showProductsByCat: true,
				selectedColumnsArr,
				loadingProducts: true
			},
			async () => {
				await this.loadProductsByCat(id, groupName)
			}
		)
	}

	loadProductsByCat = async (id, groupName, ...other) => {
		try {
			const response = await fetchApi(
				`/api/products/loadProducts/${id}`,
				'GET',
				{}
			)
			if (response.status === 200) {
				const { data } = response.data
				const vats = this.companyVatRates
				const map = new Map()
				vats.forEach(value => {
					map.set(value._id, value.value)
				})

				const arr = Array.isArray(data)
					? data.map(value => {
							const obj = Object.assign({}, value)
							if (typeof value.vat !== 'undefined') {
								obj.vat = map.get(value.vat)
							}
							return obj
					  })
					: []

				if (
					other
						.map(item => {
							return item.includes('updated') || item.includes('new')
						})
						.includes(true)
				) {
					const group = this.state.tableArray.filter(group => group._id === id)
					if (group.length === 1) {
						let newColumnsArray = []
						Object.entries(group[0].columns).forEach(([item, value]) => {
							if (value === true) {
								const newField = { field: item }
								newColumnsArray.push(newField)
							}
						})
						const newArrayOfColumns = this.columns.filter(column => {
							return (
								newColumnsArray.filter(newColumn => {
									return newColumn.field === column.field
								}).length !== 0
							)
						})
						if (this.state.selectedColumnsArr !== newArrayOfColumns) {
							this.setState({
								selectedColumnsArr: newArrayOfColumns
							})
						}
					}
				}

				this.setState({
					productsArray: arr,
					showProductsByCat: true,
					groupId: id,
					groupName: groupName,
					loadingProducts: false
				})
			}

			if (response.status === 204) {
				console.info('status 204')
				return null
			}
		} catch (err) {
			console.error(err.message)
		}
	}

	category = () => {
		const { tableArray, groupId } = this.state
		if (typeof tableArray !== 'undefined' && tableArray.length > 0) {
			const category = tableArray.filter(category => category._id === groupId)
			if (category.length === 0) {
				return {}
			} else {
				return category[0]
			}
		} else {
			return {}
		}
	}
	product = () => {
		const { productsArray, productId } = this.state
		const product = productsArray.filter(product => product._id === productId)
		if (product.length === 0) {
			return {}
		} else {
			return product[0]
		}
	}
	render() {
		const {
			tableArray,
			showAllProducts,
			isModalSidebarOpen,
			isModalStorageTableOpen,
			groupId,
			groupName,
			actionName,
			productId,
			productsArray,
			showProductsByCat,
			selectedColumnsArr,
			loading,
			loadingProducts,
			jobTypesArray
		} = this.state

		const { t } = this.props
		return (
			<div className="MainStorage">
				<div className="title-row">{t('storage.title')}</div>

				<div className="content-wrapper">
					<div className="col-left">
						<StorageSidebar
							tableArray={tableArray}
							onSearchChange={this.onSearchChange}
							isSearched={this.isSearched}
							onModifyCategory={this.onModifyCategory}
							onModifyProduct={this.onModifyProduct}
							modalOpen={isModalSidebarOpen}
							modalClose={this.modalSidebarClose}
							modalCancel={this.handleCancelModalCategory}
							groupId={groupId}
							groupName={groupName}
							productId={productId}
							actionName={actionName}
							columns={this.columns}
							storageSettings={this.storageSettings}
							productsArray={productsArray}
							loadProductsByCat={this.loadProductsByCat}
							showProductsByCat={showProductsByCat}
							onShowProductsByCat={this.onShowProductsByCat}
							selectedColumnsArr={selectedColumnsArr}
							product={this.product}
							loadCategories={this.onLoad}
							loading={loading}
							jobTypesArray={jobTypesArray}
							category={this.category}
							onLoadingProducts={this.onLoadingProducts}
						/>
					</div>

					<div className="col-right">
						<StorageTable
							onShowAllProducts={this.onShowAllProducts}
							showAllProducts={showAllProducts}
							tableArray={tableArray}
							columns={this.columns}
							storageSettings={this.storageSettings}
							onModifyProduct={this.onModifyProduct}
							onModifyCategory={this.onModifyCategory}
							groupId={groupId}
							groupName={groupName}
							productId={productId}
							actionName={actionName}
							productsArray={productsArray}
							loadProductsByCat={this.loadProductsByCat}
							showProductsByCat={showProductsByCat}
							selectedColumnsArr={selectedColumnsArr}
							product={this.product}
							modalOpen={isModalStorageTableOpen}
							modalClose={this.modalStorageTableClose}
							modalCancel={this.handleCancelModalProduct}
							loadingProducts={loadingProducts}
							onLoadingProducts={this.onLoadingProducts}
							jobTypesArray={jobTypesArray}
							loadCategories={this.onLoad}
							category={this.category}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(MainStorage)
