import React from 'react'
import ModalWindow from '../ModalWindow/ModalWindow'
import { InfoMessage } from './../Dialogs'

const InfoModal = ({modalVisible, closeModal, accept, cancel, warningMessage}) => {

    return (
        <ModalWindow
            width="modalWidth600"
            open={modalVisible}
            handleClose={closeModal}
        >
            <InfoMessage
                accept={accept}
                cancel={cancel}
                warningMessage={warningMessage}
            />
        </ModalWindow>
    )
}

export default InfoModal
