import React, { Component } from 'react'
import ModalWindow from './../ModalWindow/ModalWindow'
import { OnExitConfirmation } from './../Dialogs'

export default class EscapeHandler extends Component {
  render() {
    const {
      modalVisible,
      closeModal,
      cancel,
      accept,
      document,
      confirmationQuestion,
      warningMessage,
    } = this.props

    return (
      <ModalWindow
        width="modalWidth600"
        open={modalVisible}
        handleClose={closeModal}
      >
        <OnExitConfirmation
          cancel={cancel}
          accept={accept}
          document={document}
          confirmationQuestion={confirmationQuestion}
          warningMessage={warningMessage}
        />
      </ModalWindow>
    )
  }
}
