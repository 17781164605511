import {
	CHANGE_FIELD_BILLID,
	SET_ORDER_DATA,
	CHANGE_ANOTHER_FIELD
} from '../../actions/actionTypes'

const initialState = {
	order: {},
	ordersVsInvoices: false
}
const ReducerOrder = (state = initialState, action) => {
	switch (action.type) {
		case SET_ORDER_DATA: {
			return {
				...state,
				order: action.payload
			}
		}
		case CHANGE_FIELD_BILLID:
			return {
				...state,
				order: {
					...state.order,
					[action.field]: action.value
				}
			}
		case CHANGE_ANOTHER_FIELD: {
			return {
				...state,
				[action.field]: action.value
			}
		}
		default:
			return state
	}
}

export default ReducerOrder
