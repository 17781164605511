import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const AuthRoute = ({ component: Component, ...rest }) => {
	const userData = true
	return (
		<Route
			{...rest}
			render={props =>
				userData ? <Component {...props} /> : <Redirect to="/login" />
			}
		/>
	)
}
export default AuthRoute
