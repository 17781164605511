import React, { useState, useEffect } from 'react'
// import { fetchApi } from '../../../services/api'
import { useTranslation } from 'react-i18next'
import './ProductGroupList.css'

import { makeStyles } from '@material-ui/core/styles'
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	Checkbox,
	IconButton,
	ListItemSecondaryAction,
	Box,
	Divider,
	Tooltip,
	Button
} from '@material-ui/core'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

// import { check } from 'prettier'
const useStyles = makeStyles(theme => ({
	mainList: {
		height: '45vh',
		[theme.breakpoints.up('sm')]: {
			height: '55vh'
		},
		[theme.breakpoints.up('md')]: {
			height: '60vh'
		},
		[theme.breakpoints.up('lg')]: {
			height: '59vh'
		},
		[theme.breakpoints.up('xl')]: {
			height: '60vh'
		},
		overflow: 'auto',
		backgroundColor: '#ffffff'
	},
	list: {
		backgroundColor: '#ffffff'
	}
}))
const ProductGroupList = ({
	tableArray,
	pattern,
	isSearched,
	onModifyCategory,
	showProductsByCat,
	onShowProductsByCat,
	columns,
	columnsArr
}) => {
	const { t } = useTranslation()
	const classes = useStyles()
	let [open, setOpen] = useState(-1)
	const [checked, setChecked] = useState([])
	const [newGroup, setNewGroup] = useState(false)
	const [selectedColumnsArr, setSelectedColumnsArr] = useState(columnsArr)

	const handleClick = (index, group, action) => {
		if (index === open) {
			setSelectedColumnsArr([])
			return setOpen(-1)
		}
		fillChecked(group, action)
		if (action !== 'showCategory') {
			setOpen(index)
			setNewGroup(true)
		}
	}

	const fillChecked = (group, action) => {
		const nextChecked = []
		Object.entries(group.columns).forEach(([itemKey, value]) => {
			if (value === true) {
				nextChecked.push(itemKey)
			}
		})
		setChecked(nextChecked)
		_defineColumns(group._id, group.name, nextChecked, action)
	}
	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value)
		let newChecked = [...checked]
		setNewGroup(false)
		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked = [
				...checked.slice(0, currentIndex),
				...checked.slice(currentIndex + 1)
			]
		}
		setChecked(newChecked)
	}

	const _defineColumns = async (groupId, groupName, nextChecked, action) => {
		setSelectedColumnsArr([])
		nextChecked.length > 0
			? nextChecked.forEach(checkedColumn => {
					let selectedColumn = columns.filter(
						column => column.field === checkedColumn
					)
					const array = selectedColumnsArr.filter(item => {
						return item.field === selectedColumn[0].field
					})
					if (array.length === 0) {
						selectedColumnsArr.push(selectedColumn[0])
					}
			  })
			: alert(t('storage.selectColumn'))
		selectedColumnsArr.length > 0 &&
			action !== 'expand' &&
			(await onShowProductsByCat(groupId, selectedColumnsArr, groupName))
	}

	useEffect(() => {
		showProductsByCat === false && setOpen(-1)
	}, [showProductsByCat])

	if (typeof tableArray !== 'undefined') {
		return (
			<div className="ProductGroupList">
				{tableArray.filter(isSearched(pattern)).map((group, index) => {
					if (group) {
						// const groupId = group._id
						return (
							<React.Fragment key={group._id}>
								<ListItem
									selected={open === index}
									onClick={() => handleClick(index, group, 'showCategory')}
								>
									<ListItemText>
										<Box
											style={{
												maxWidth: '80%',
												overflowWrap: 'break-word',
												cursor: 'pointer'
											}}
										>
											{group.name}
										</Box>
									</ListItemText>

									<ListItemSecondaryAction>
										<IconButton
											aria-label="expand"
											onClick={() => handleClick(index, group, 'expand')}
											id={group._id}
										>
											{index !== open ? <ExpandMore /> : <ExpandLess />}
										</IconButton>
										<IconButton
											aria-label="editcategory"
											onClick={() => onModifyCategory(group._id, 'editCat')}
										>
											<Tooltip title="Edit">
												<EditOutlinedIcon color="action" />
											</Tooltip>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
								<Divider variant="middle" />
								<Collapse in={index === open} timeout="auto" unmountOnExit>
									<List className={classes.list}>
										{typeof group.jobTypes !== 'undefined' ? (
											<ListItem>
												<ListItemText>
													{t('orders.jobs')}: {group.jobTypes.join(', ')}
												</ListItemText>
											</ListItem>
										) : null}
										<ListItemText>
											<Box textAlign="center">{t('storage.selectColumns')}</Box>
										</ListItemText>
										{Object.entries(group.columns).map(([itemKey, value]) => {
											if (index === open) {
												let columnName = ''
												if (
													columns.filter(column => column.field === itemKey)[0]
														.title !== undefined
												)
													columnName = columns.filter(
														column => column.field === itemKey
													)[0].title
												else columnName = itemKey

												const labelId = `checkbox-list-label-${itemKey}`

												if (value === true) {
													if (newGroup && checked.indexOf(itemKey) === -1) {
														checked.push(itemKey)
													}

													return (
														<React.Fragment key={itemKey}>
															<ListItem
																role={undefined}
																dense
																button
																onClick={handleToggle(itemKey)}
															>
																<ListItemIcon>
																	<Checkbox
																		edge="start"
																		checked={checked.indexOf(itemKey) !== -1}
																		tabIndex={-1}
																		disableRipple
																		inputProps={{ 'aria-labelledby': labelId }}
																	/>
																</ListItemIcon>
																<ListItemText id={labelId}>
																	{columnName}
																</ListItemText>
															</ListItem>
															<Divider variant="middle" />
														</React.Fragment>
													)
												} else return null
											} else return null
										})}

										<Box display="flex" justifyContent="flex-end" m={1} p={1}>
											<Button
												variant="contained"
												color="primary"
												style={{ backgroundColor: '#01579B' }}
												onClick={async () =>
													await _defineColumns(
														group._id,
														group.name,
														checked,
														'showCategory'
													)
												}
											>
												{t('storage.showProductsIn')} {group.name}
											</Button>
										</Box>
									</List>
								</Collapse>
							</React.Fragment>
						)
					} else return null
				})}
			</div>
		)
	} else return null
}
export default ProductGroupList
