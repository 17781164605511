import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Currency extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currencySymbol: ''
		}
	}

	componentDidMount() {
		if (JSON.parse(localStorage.getItem('userData')) !== null) {
			const userData = JSON.parse(localStorage.getItem('userData'))
			const { currency } = userData.companyId
			switch (currency) {
				case 'EUR':
					this.setState({
						currencySymbol: '€'
					})
					break

				case 'SEK':
					this.setState({
						currencySymbol: 'kr'
					})
					break

				case 'USD':
					this.setState({
						currencySymbol: '$'
					})
					break

				default:
					break
			}
		}
	}

	render() {
		const { currencySymbol } = this.state
		return (
			<>
				{currencySymbol}
			</>
		)
	}
}

export default withTranslation()(Currency)
