import React, { Component } from 'react'
import './Footer.css'
import Grid from '@material-ui/core/Grid'

class Footer extends Component {
	render() {
		return (
			<footer id="footer" className="hide-print">
				<Grid container display="flex" justify="space-between">
					<span style={{ marginLeft: 50 }}>
						<a href="http://www.neemia.fi">Neemia Ltd</a> | Customer Service: 
						<a id="footer_mail" href="mailto:tuki@neemia.fi">
							tuki@neemia.fi
						</a> | <a href="/files/Tietosuojalauseke.pdf" target="_blank"> Tietosuojalauseke </a>
					</span>
				</Grid>
			</footer>
		)
	}
}

export default Footer
