import React from 'react'
import './dialogs.css'

import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const OnExitConfirmation = ({
	accept,
	cancel,
	confirmationQuestion,
	warningMessage,
	document
}) => {
	const { t } = useTranslation()
	return (
		<Grid>
			<Grid container item style={{ marginTop: 15, marginBottom: 15 }}>
				<Typography variant="h6">
					{!!document ? document + ': ' : null}
					{confirmationQuestion}
				</Typography>
			</Grid>
			<Grid container item style={{ marginBottom: 15 }}>
				<Typography variant="body1">{warningMessage}</Typography>
			</Grid>
			<div className="modal-buttons">
				<div className="btn-33 goback-button" onClick={cancel}>
					<div className="button-text">{t('orders.no')}</div>
				</div>
				<div className="btn-33 edit-button" onClick={accept}>
					<div className="button-text">{t('orders.yes')}</div>
				</div>
			</div>
		</Grid>
	)
}
export default OnExitConfirmation
