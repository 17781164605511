import React from 'react'
import { Prompt } from 'react-router-dom'
import ModalWindow from './../ModalWindow/ModalWindow'
import { OnExitConfirmation } from './../Dialogs'
import { withTranslation } from 'react-i18next'

class RouteLeavingGuardSettings extends React.Component {
    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    }
    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location,
    })
    closeModal = (callback) => this.setState({
        modalVisible: false
      }, callback)

    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state
        const { shouldBlockNavigation } = this.props
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            console.log("handleBlockedNavigation showModal", nextLocation)
            this.showModal(nextLocation)
            return false
        }
        console.log("handleBlockedNavigation return true")
        return true
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { navigate } = this.props
        const { lastLocation } = this.state
        if (lastLocation) {
            console.log("lastlocation", lastLocation)
            this.setState({
                confirmedNavigation: true
            }, () => {
                // // Navigate to the previous blocked location with your navigate function
                // navigate(lastLocation)
                navigate(lastLocation.pathname)
            })
        }
    })
    render() {
        const { when, t } = this.props
        const { modalVisible } = this.state
        return (
            <>
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation} />
                <ModalWindow
                    width="modalWidth600"
                    open={modalVisible}
                    handleClose={this.closeModal}
                >
                    <OnExitConfirmation
                        cancel={() => this.closeModal()}
                        accept={this.handleConfirmNavigationClick}
                        confirmationQuestion={t('orders.exitWithoutSaving')}
                        document={this.props.document}
                        warningMessage={t('orders.youMayLoseData')}
                    />
                </ModalWindow>
            </>
        )
    }
}
export default withTranslation()(RouteLeavingGuardSettings)
