import React, { Component } from 'react'
import HeaderBar from './HeaderBar'
import Footer from './Footer'

import { withRouter } from 'react-router-dom'
import { fetchApi } from '../../services/api'

const AppContext = React.createContext()
class CoreLayout extends Component {
	constructor(props) {
		super(props)
		this.state = {
			customer: {},
			active: [false, false, false, false, false, false, false]
		}
	}

	setUser = user => {
		this.setState({ user })
	}

	setCustomer = customer => {
		this.setState({ customer })
	}

	onLogout = async () => {
		try {
			const response = await fetchApi(
				'/api/logout',
				'GET'
			)
			if (response.data.message === 'success') {
				localStorage.removeItem('userData')
				localStorage.removeItem('availablePages')
				window.location.href = '/login'
			}
		} catch (err) {
			console.error('onLogout', err.message)
		}
	}

	addActive = index => {
		const activeArray = []
		for (let i = 0; i <= 6; i++) {
			if (i !== index) {
				activeArray.push(false)
			} else if (i === index) {
				activeArray.push(true)
			}
		}
		this.setState({
			active: [...activeArray]
		})
	}

	render() {
		const { pathname } = this.props.location
		const path = pathname.split('/')[1]
		let userInfo = localStorage.getItem('userData')
		if (userInfo) {
			userInfo = JSON.parse(userInfo)
		}
		const {
			history,
			location
		} = this.props

		return (
			<React.Fragment>
				<AppContext.Provider
					value={{
						...this.state,
						setUser: this.setUser,
						setCustomer: this.setCustomer
					}}
				>
					<HeaderBar
						history={history}
						location={location}
						children={this.props.children}
						onLogout={this.onLogout}
						path={path}
						pathnameBill={pathname}
						addActive={this.addActive}
						userInfo={userInfo}
					/>
					{userInfo && (path !== 'pdf') ? (
						<footer style={{ zIndex: '1000'}}>
							<Footer history={history}/>
						</footer>
					) : (
						''
					)}
				</AppContext.Provider>
			</React.Fragment>
		)
	}
}

export default withRouter(CoreLayout)

const AppConsumer = AppContext.Consumer
export { AppConsumer }
