import React from 'react'

import {
  Modal,
  Backdrop,
  Fade
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  modalWidth600: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '600px',
    height: '110vh'
  },
  modalWidth700: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '700px',
    height: '110vh'
  },
  modalWidth900: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '900px',
    height: '110vh'
  },
  modalWidth1000: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '1000px',
    height: '110vh'
  },
  modalWidth1100: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '1100px',
    height: '110vh'
  },
  modalWidth1200: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '1200px',
    height: '110vh'
  },
  modalWidth1300: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '1300px',
    height: '110vh'
  },
  modalWidth1400: {
    display: 'block',
    position: 'relative',
    margin: '50px auto',
    maxWidth: '1400px',
    height: '110vh'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderTop: '4px solid #007DC6',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
    maxHeight: '80%',
    outline: 'none'
  },
  modalEditUser: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '90%',
    margin: 'auto',
    height: '120vh'
  },
  reminder: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 800,
    maxWidth: '80%',
    overflow: 'auto',
    maxHeight: '80%'
  },
}))

const ModalWindow = (props) => {
  const classes = useStyles()
  const { type, width } = props

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes[width]}
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") { // disableBackDropClick 
          props.handleClose();
        }
      }}
      onEscapeKeyDown={props.handleClose}
      // disableBackdropClick // is deprecated
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      // onBackdropClick={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Fade in={props.open}>
        <div className={type === 'reminder' ? classes.reminder : classes.paper}>
          {props.children}
        </div>
      </Fade>
    </Modal>
  )
}
export default ModalWindow
