import { combineReducers } from 'redux'
import ReducerCustomerInfo from './clients/customerInfo-reducer'
import ReducerTimeCard from './timecard/TimeCard-reducer'
import ReducerRecording from './timecard/TimeCardRecording-reducer'
import ReducerOrder from './orders'
import {
	ReducerTyonjonohaut,
	ReducerUsers
} from './settings/Tyonjonohaut-reducer'
import ReducerPricelist from './settings/pricelist-reducer'

const allReducers = combineReducers({
	customerinfo: ReducerCustomerInfo,
	timecard: ReducerTimeCard,
	recording: ReducerRecording,

	workOrderQueue: ReducerTyonjonohaut,
	users: ReducerUsers,
	order: ReducerOrder,
	pricelist: ReducerPricelist
})

export default allReducers
