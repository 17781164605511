import React from 'react'
import MaterialComTable from '../../../components/MaterialUiTable/MaterialComTable'
import {
	MTableToolbar,
	MTablePagination
} from '@material-table/core';

import AddIcon from '@material-ui/icons/Add'
import {
	Typography,
	Button,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import "./ProductList.css"

const ProductList = ({
	columns,
	actionName,
	groupId,
	productId,
	onModifyCategory,
	onModifyProduct,
	showAllProducts,
	showProductsByCat,
	groupName,
	selectedColumnsArr,
	productsArray,
	loadingProducts,
	onShowAllProducts
}) => {
	const { t } = useTranslation()

	const noResultsMessage = <Typography align='left'>{t('storage.noDataToDisplay')}</Typography>

	const opts = {
		draggable: false,
		search: true,
		headerStyle: {
			backgroundColor: '#007DC6',
			color: '#FFF',
			scrollSnapAlign: 'end',
			scrollMargin: '3px'
		},
		maxBodyHeight: showAllProducts ? '60vh' : '55vh',
		minBodyHeight: showAllProducts ? '60vh' : '55vh',
		pageSizeOptions: [5, 10, 20],
		pageSize: 20,
		emptyRowsWhenPaging: false,
		searchFieldStyle: {
			backgroundColor: 'rgb(253, 251, 251)',
			borderRadius: 5,
			paddingLeft: 10
		}
	}
	const title = showAllProducts ? t('storage.allProducts') : groupName
	const tableColumns = showAllProducts ? columns : selectedColumnsArr
	if (loadingProducts) {
		return (
			<div className="ProductList">
				<div className="wrapper">
					<div className="medium-loader"></div>
				</div>
			</div>
		)
	} else {
		return (
			<div className="ProductList">
				<MaterialComTable
					theme="storage"
					title={""}
					columns={tableColumns}
					data={productsArray}
					opts={opts}
					loadingProducts={loadingProducts}
					onRowClick={(event, rowData) =>
						onModifyProduct(
							groupId,
							rowData._id,
							(actionName = 'showProd'),
							productsArray
						)
					}
					components={{
						Pagination: ({ classes, ...other }) => (
							<td
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginTop: 8
								}}
							>
								<div>
									{' '}
									{showProductsByCat &&
										<Button
											variant="contained"
											aria-label="editcategory"
											onClick={() =>
												onModifyCategory(groupId, (actionName = 'editCat'))
											}
											style={{
												margin: '0 0 10px 20px',
												color: 'white',
												backgroundColor: '#007DC6'
											}}
										>
											{t('storage.editCategory')}
										</Button>
									}
									<Button
										variant="contained"
										style={{
											margin: '0 0 10px 20px',
											color: 'white',
											backgroundColor: '#007DC6'
										}}
										onClick={() =>
											onModifyProduct(
												groupId,
												productId,
												(actionName = 'addProd')
											)
										}
									>
										<AddIcon />
										{t('storage.newProduct')}
									</Button>
								</div>

								<div style={{ alignContent: 'flex-end' }}>
									<MTablePagination {...other} />
								</div>
							</td>
						),
						Toolbar: props => {
							const propsCopy = { ...props }
							propsCopy.showTitle = false
							propsCopy.searchFieldAlignment = "left"
							return (
								<div
									style={{
										//backgroundColor: '#dedede',
										display: 'flex',
										flexDirection: 'row',
										flexWrap: 'wrap-reverse',
										justifyContent: 'space-between',
										borderTop: '4px solid rgb(0, 125, 198)',
										backgroundColor: '#FFF',
										borderRadius: '10px'
										// border: '1px solid rgba(70, 68, 68, 0.404)',
										// borderRadius: '10px 10px 0 0'
									}}
								>
									<div>
										<h1 className="toolbar-title">{title}</h1>
									</div>
									<div>
										<MTableToolbar {...propsCopy} />
									</div>
									<div className="toolbar-button">
										{showProductsByCat && (
											<Button
												variant="contained"
												aria-label="showAllProducts"
												onClick={() => onShowAllProducts()}
												style={{
													margin: '0 0 10px 20px',
													color: 'white',
													backgroundColor: '#007DC6'
												}}
											>
												{t('storage.showAllProducts')}
											</Button>
										)}
									</div>
								</div>
							)
						}
					}}
					localization={{
						toolbar: {
							searchPlaceholder: `${t('storage.searchProduct')}`
						},
						body: {
							emptyDataSourceMessage: noResultsMessage
						}
					}}
				/>
			</div>
		)
	}
}
export default ProductList
