import React, { Component } from 'react'
import ProductList from './ProductList'
import ModalWindow from './../../../components/ModalWindow/ModalWindow'
import ModalButtons from '../../orders/Order/modals/ModalButtons'
import EditCategory from '../modals/EditCategory'
import AddProduct from './../modals/AddProduct'
import ShowProduct from './../modals/ShowProduct'
import { EscapeHandler } from './../../../components/EventHandler'
import { withTranslation } from 'react-i18next'

class StorageTable extends Component {

	constructor(props) {
		super(props)
		this.state = {
			isCancelConfirmation: false,
			isEdited: false
		}
	}

	onCancel = () => {
		this.state.isEdited ?
			this.setState({isCancelConfirmation: true})
			: this.props.modalCancel()
	}

	onModalClose = () => {
		this.setState({isEdited: false})
		this.props.modalClose()
	}

	closeCancelConfirmation = () => {
		this.setState({isCancelConfirmation: false})
	}

	onAcceptCancel = () => {
		this.setState({
			isCancelConfirmation: false,
			isEdited: false
		})
		this.props.modalCancel()
	}

	handleDataChanged = value => {
		this.setState({isEdited: value})
	}

	render() {

		const {
			showAllProducts,
			onShowAllProducts,
			tableArray,
			columns,
			storageSettings,
			onModifyCategory,
			onModifyProduct,
			groupId,
			groupName,
			productId,
			actionName,
			showProductsByCat,
			productsArray,
			selectedColumnsArr,
			loadingProducts,
			modalClose,
			modalOpen,
			category,
			loadCategories,
			jobTypesArray,
			handleDeleteCategory,
			product,
			onLoadingProducts,
			t
		} = this.props

		if (loadingProducts) {
			return (
				<div style={{ padding: 40}}>
					<div className="medium-loader" />
				</div>
			)
		} else {
			return (
				<div>
					<EscapeHandler
						modalVisible={this.state.isCancelConfirmation}
						closeModal={this.closeCancelConfirmation}
						cancel={this.closeCancelConfirmation}
						accept={this.onAcceptCancel}
						document={t('orders.warning')}
						confirmationQuestion={t('orders.exitWithoutSaving')}
						warningMessage={t('orders.youMayLoseData')}
					/>

					<ProductList
						columns={columns}
						storageSettings={storageSettings}
						groupId={groupId}
						productId={productId}
						actionName={actionName}
						onModifyProduct={onModifyProduct}
						onModifyCategory={onModifyCategory}
						groupName={groupName}
						selectedColumnsArr={selectedColumnsArr}
						productsArray={productsArray}
						onShowAllProducts={onShowAllProducts}
						showAllProducts={showAllProducts}
						showProductsByCat={showProductsByCat}
						loadingProducts={loadingProducts}
					/>

					<ModalWindow
						width="modalWidth900"
						handleClose={this.onCancel}
						open={modalOpen}
						style={{ maxHeight: '80%' }}
					>

						<ModalButtons handleClose={this.onCancel} />

						{actionName === 'editCat' && (
							<EditCategory
								handleClose={modalClose}
								groupId={groupId}
								category={category}
								columns={columns}
								storageSettings={storageSettings}
								onModifyCategory={onModifyCategory}
								actionName={actionName}
								loadCategories={loadCategories}
								jobTypesArray={jobTypesArray}
								handleDeleteCategory={handleDeleteCategory}
								handleCancel={this.onCancel}
								handleDataChanged={this.handleDataChanged}
								isEdited={this.state.isEdited}
								onLoadingProducts={onLoadingProducts}
							/>
						)}
						{actionName === 'addProd' && (
							<AddProduct
								handleClose={this.onModalClose}
								columns={columns}
								storageSettings={storageSettings}
								groupId={groupId}
								tableArray={tableArray}
								handleCancel={this.onCancel}
								handleDataChanged={this.handleDataChanged}
								isEdited={this.state.isEdited}
							/>
						)}
						{actionName === 'showProd' && (
							<ShowProduct
								handleClose={this.onModalClose}
								columns={columns}
								storageSettings={storageSettings}
								groupId={groupId}
								productId={productId}
								productsArray={productsArray}
								product={product}
								onModifyProduct={onModifyProduct}
								handleCancel={this.onCancel}
								handleDataChanged={this.handleDataChanged}
								isEdited={this.state.isEdited}
							/>
						)}
					</ModalWindow>
				</div>
			)
		}
	}
}

export default withTranslation()(StorageTable)
