import React, { useState, useEffect } from 'react'
import './InputDialog.css'
import '../../css/ModalInputs.css'
import ModalWindow from '../ModalWindow/ModalWindow'
import ModalButtons from '../../pages/orders/Order/modals/ModalButtons'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import Currency from '../Currency/Currency'
import DateFnsUtils from '@date-io/date-fns'
import {
    Grid,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Switch,
    Tooltip
} from '@material-ui/core'
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers'

import { useTranslation } from 'react-i18next'
import moment from 'moment'

const CreditNote = ({ accept, inputMessage, onDataChange, closeModal, invoiceDate }) => {
	const { t } = useTranslation()
	const [settings, setSettings] = React.useState({
		message: '',
		date: invoiceDate
	})
	const handleChange = event => {
		setSettings({ ...settings, message: event.target.value })
		onDataChange()
	}
	const handleDateChange = date => {
		setSettings({ ...settings, date: date })
		onDataChange()
	}
	const handleClear = () => {
		setSettings({ ...settings, message: '' })
		onDataChange()
	}

	return (
		<div className="InputDialog">
			<h1>{inputMessage}</h1>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('reports.reason')}:{' '}
					</Typography>
				</div>
				<div className="input-content input-width-60">
					<TextField
						name="reason"
						type="text"
            multiline
						rows={3}
						value={settings.message}
						inputProps={{
							maxLength: 70
						}}
						margin="dense"
						variant="outlined"
						onChange={handleChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Tooltip title={t('settings.clear')} placement="top-end">
										<IconButton onClick={handleClear}>
											<CancelIcon />
										</IconButton>
									</Tooltip>
								</InputAdornment>
							)
						}}
					/>
				</div>
			</div>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('reports.date')}:{' '}
					</Typography>
				</div>
				<div className="input-content input-width-33">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="date-picker"
							required
							disableToolbar
							variant="inline"
							inputVariant="outlined"
							format="dd/MM/yyyy"
							id="inv-date"
							name="invoiceDate"
							value={settings.date}
							onChange={handleDateChange}
							inputProps={{ readOnly: true }}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							autoOk={true}
							minDate={invoiceDate}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>

			<Grid container item justify="flex-end">
				{/* <Button onClick={() => setMessage('')}>{t('settings.clear')}</Button> */}
				{/* <Button
					onClick={() => accept(message)}
					style={{ marginLeft: '1vh', color: 'green' }}
				>
					{t('reports.next')}
				</Button> */}
			</Grid>
			<div className="modal-buttons">
				<div className="btn-33 remove-button" onClick={closeModal}>
					<div className="button-icon">
						<CancelIcon />
					</div>
					<div className="button-text">{t('orders.cancel')}</div>
				</div>

				<div className="btn-33 edit-button" onClick={() => accept(settings)}>
					<div className="button-icon">
						<SaveIcon />
					</div>
					<div className="button-text">{t('reports.next')}</div>
				</div>
			</div>
		</div>
	)
}

const Reminder = ({ accept, inputMessage, onDataChange, closeModal, reminderSettings }) => {

  const { t } = useTranslation()
  const isPaymentInterest = !!reminderSettings.interestRate
      ? true : false

  const isPaymentFee = !!reminderSettings.reminderCosts
      ? true : false

  const [state, setState] = React.useState({
      checkedA: isPaymentFee,
      checkedB: isPaymentInterest,
  })

  const handleChecked = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked })
  }

  useEffect(() => {
      if (state.checkedA === false) setSettings({...settings, amount: 0})
      if (state.checkedB === false) setSettings({...settings, interestRate: 0})
  }, [state])

  const [settings, setSettings] = React.useState({
      message: reminderSettings.message,
      amount: reminderSettings.reminderCosts,
      interestRate: reminderSettings.interestRate,
      dueDate: moment()
  })

  const handleChange = (event) => {
      const value = event.target.name === 'message' ? event.target.value : parseFloat(event.target.value)
      setSettings({ ...settings, [event.target.name]: value })
      onDataChange()
  }

  const handleDateChange = date => {
      setSettings({ ...settings, dueDate: date })
      onDataChange()
  }

  const handleClear = () => {
      setSettings({ ...settings, message: '' })
      onDataChange()
  }

  return (
		<div className="InputDialog">
			<h1>{inputMessage}</h1>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('settings.reminderInvoice.reminderMessage')}:{' '}
					</Typography>
				</div>
				<div className="input-content">
					<TextField
						fullWidth
						name="message"
						type="text"
						multiline
						rows={4}
						value={settings.message}
						inputProps={{
							maxLength: 100
						}}
						margin="dense"
						variant="outlined"
						onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t('settings.clear')} placement="top-end">
                  <IconButton onClick={handleClear}>
                    <CancelIcon />
                  </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
					/>
				</div>
				{/* <div className="input-content">
					<div className="btn-33 remove-button" onClick={handleClear}>
          <div className="button-icon">
						<CancelIcon />
					</div>
					<div className="button-text">{t('settings.clear')}</div>
					</div>
				</div> */}
			</div>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('settings.reminderInvoice.latePaymentFee')}:{' '}
					</Typography>
				</div>
				<div className="input-checkbox">
					<Switch
						checked={state.checkedA}
						onChange={handleChecked}
						name="checkedA"
						inputProps={{ 'aria-label': 'reminder costs checkbox' }}
						color="primary"
					/>
				</div>
				<div className="input-content input-width-45">
					<TextField
						name="amount"
						type="number"
						value={settings.amount}
						disabled={!state.checkedA}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Currency />
								</InputAdornment>
							),
							inputProps: {
								min: 0,
								step: 1
							}
						}}
						margin="dense"
						variant="outlined"
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('settings.reminderInvoice.latePaymentInterest')}
					</Typography>
				</div>
				<div className="input-checkbox">
					<Switch
						checked={state.checkedB}
						onChange={handleChecked}
						name="checkedB"
						inputProps={{ 'aria-label': 'reminder interest rate checkbox' }}
						color="primary"
					/>
				</div>
				<div className="input-content input-width-45">
					<TextField
						name="interestRate"
						type="number"
						value={settings.interestRate}
						disabled={!state.checkedB}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">%</InputAdornment>
							),
							inputProps: {
								min: 0,
								step: 1
							}
						}}
						margin="dense"
						variant="outlined"
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className="input-row">
				<div className="input-label">
					<Typography style={{ paddingTop: 10.5 }}>
						{t('orders.due')}
					</Typography>
				</div>
				<div  className="input-content input-width-33">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							className="date-picker"
							required
							disableToolbar
							variant="inline"
							inputVariant="outlined"
							format="dd/MM/yyyy"
							id="due-date"
							name="dueDate"
							value={settings.dueDate}
							onChange={handleDateChange}
							inputProps={{ readOnly: true }}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
							autoOk={true}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>

			<div className="modal-buttons">
				<div className="btn-33 remove-button" onClick={closeModal}>
					<div className="button-icon">
						<CancelIcon />
					</div>
					<div className="button-text">{t('orders.cancel')}</div>
				</div>

				<div className="btn-33 edit-button" onClick={() => accept(settings)}>
					<div className="button-icon">
						<SaveIcon />
					</div>
					<div className="button-text">{t('reports.next')}</div>
				</div>
			</div>
		</div>
	)
}

const InputDialog = ({ modalVisible, closeModal, accept, inputMessage, onDataChange, inputBlock, reminderSettings, invoiceDate }) => {
  return (
    <ModalWindow
      width="modalWidth700"
      open={modalVisible}
      handleClose={closeModal}
    >
			<ModalButtons handleClose={closeModal} />
      {inputBlock === 'creditNote' ?
        <CreditNote
          accept={accept}
          inputMessage={inputMessage}
          onDataChange={onDataChange}
          closeModal={closeModal}
          invoiceDate={invoiceDate}
        /> : inputBlock === 'reminder' &&
        <Reminder
          accept={accept}
          inputMessage={inputMessage}
          onDataChange={onDataChange}
          closeModal={closeModal}
          reminderSettings={reminderSettings}
          style={{ width: '50%' }}
        />
      }
    </ModalWindow>
  )
}

export default InputDialog
