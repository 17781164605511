import React from 'react'
import ModalWindow from './../ModalWindow/ModalWindow'
import { InfoConfirmation } from './../Dialogs'

const InfoHandler = ({modalVisible, closeModal, cancel, accept, confirmationQuestion, warningMessage}) => {

	return (
		<ModalWindow
			width="modalWidth600"
			open={modalVisible}
			handleClose={closeModal}
		>
			<InfoConfirmation
				cancel={cancel}
				accept={accept}
				confirmationQuestion={confirmationQuestion}
				warningMessage={warningMessage}
			/>
		</ModalWindow>
	)
}

export default InfoHandler
