import React from 'react'

import {
	Grid,
	Typography,
	Button
} from '@material-ui/core'

const InfoConfirmation = ({accept, confirmationQuestion, warningMessage}) => {
	return (
		<Grid>
			<Grid container item style={{ marginBottom: 15 }}>
				<Typography variant="h6">
					{confirmationQuestion}
				</Typography>
			</Grid>
			<Grid container item style={{ marginBottom: 15 }}>
				<Typography variant="body1">
					{warningMessage}
				</Typography>
			</Grid>
			<Grid container item justify="flex-end">
				<Button
					onClick={accept}
					style={{ marginLeft: '1vh' }}
				>
					OK
				</Button>
			</Grid>
		</Grid>
	)
}
export default InfoConfirmation
