export const jobTypes = {
	Businesscard: { title: 'Businesscard jobs', printingJobs: true },
	Print: { title: 'Printing jobs', printingJobs: true },
	Largeprint: { title: 'Large printing jobs', printingJobs: true },
	Magazine: { title: 'Magazine jobs', printingJobs: true },
	Book: { title: 'Book jobs', printingJobs: true },
	File: { title: 'File finishing' },
	Design: { title: 'Design' },
	Product: { title: 'Products and services' },
	Mailing: { title: 'Mailing services' },
	Binding: {title: 'Binding'}
}
