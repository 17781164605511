import React, { lazy } from 'react'
import { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CoreLayout from './components/CoreLayout/CoreLayout'
import AuthRoute from './components/AuthRoute/AuthRoute'
import MainStorage from './pages/storage/MainStorage'
import ErrorBoundary from './HOC/ErrorBoundary'
import './App.css'
import { fetchApi } from './services/api'

const Login = lazy(() => import('./pages/login/Login'))
const Home = lazy(() => import('./pages/Home'))
const AdminPanel = lazy(() => import('./pages/adminPanel/AdminPanel'))
const Recording = lazy(() => import('./pages/timeCard/TimeCard'))
const Hour = lazy(() => import('./pages/timeCard/hourCard/hour'))
const HourlyReports = lazy(() => import('./pages/timeCard/hourlyReport/hourlyReports'))
const CustomerInfo = lazy(() => import('./pages/clients/Clients'))
const OrderBar = lazy(() => import('./pages/orders/OrdersBar'))
const Order = lazy(() => import('./pages/orders/Order/Order'))
const Billable = lazy(() => import('./pages/billing/Billable'))
const NewBill = lazy(() => import('./pages/billing/newBill/newBill'))
const Invoice = lazy(() => import('./pages/billing/newBill/Invoice'))
const Preview = lazy(() => import('./pages/billing/preview/preview'))
const SalesStatistics = lazy(() => import('./pages/Reports/salesStatistics/salesStatistics'))
const SalesPrinting = lazy(() => import('./pages/Reports/salesPrinting/salesPrinting'))
const Company = lazy(() => import('./pages/settings/company/company'))
const Users = lazy(() => import('./pages/settings/users/users'))
const PriceList = lazy(() => import('./pages/settings/priceList/priceList'))
const Settings = lazy(() => import('./pages/settings/settings1/settings'))
const Työjonohaut = lazy(() => import('./pages/settings/Tyojonohaut/Tyojonohaut'))
const Ledgers = lazy(() => import('./pages/Reports/Ledgers/Ledgers'))
const UserCustomPage = lazy(() =>	import('./pages/settings/users/UserInfo'))
const AddClientModal = lazy(() =>	import('./pages/clients/modals/AddClientModal'))
const PDFView = lazy(() => import('./pages/pdf/PDFView'))
const ForceChangePassword = lazy(() => import('./pages/forceChangePassword'))
const Guide = lazy(() => import('./pages/guide/Guide'))
const NotFound = lazy(() => import('./pages/notFound/PageNotFound'))

export default class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			availablePages: [],
			isPasswordFirstTime: false
		}
	}

	componentDidMount() {
		if (JSON.parse(localStorage.getItem('userData')) !== null) {
			const availablePages = JSON.parse(localStorage.getItem('availablePages'))
			if (availablePages !== null) {
				this.setState({
					availablePages: availablePages,
					isPasswordFirstTime: JSON.parse(localStorage.getItem('userData')).isPasswordFirstTime
				})
			} else {
				this.setState({
					availablePages: [],
					isPasswordFirstTime: false
				})
			}
		}

		// Check session
		this.check()
	}

	check = async () => {
		const run = () => {
			setTimeout(async () => {
				if (JSON.parse(localStorage.getItem('userData')) !== null) {
					try {
						const response = await fetchApi(
							'/api/profile/profile/check',
							'GET'
						)

						if (response.status === 200) {
							run()
						} else {
							window.location.href = '/login'
						}
					} catch (err) {
						// console.log('error', error)
					}
				}
			}, 600000); // Check session every 10 minutes
		}
		run()
	}

	render() {
		if (!this.state.availablePages) {
			return (
				<div className="padding-130">
					<div className="large-loader" />
				</div>
			)
		}

		if (this.state.availablePages) {

			const { availablePages, isPasswordFirstTime } = this.state

			return (
				<Suspense fallback={<div className="large-loader"/>}>
					<ErrorBoundary>
						<Router>
							<CoreLayout>
								<Switch>
									<Route exact path="/" component={(isPasswordFirstTime ? ForceChangePassword : Home)}/>
									<AuthRoute
										exact
										path="/admin/panel"
										component={AdminPanel}
									/>
									<AuthRoute
										exact
										path="/timecard/record"
										component={availablePages.length !== 0 && availablePages.includes('timecard')
											? (isPasswordFirstTime ? ForceChangePassword : Recording)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/timecard/hourspecification"
										component={availablePages.length !== 0 && availablePages.includes('timecard')
											? (isPasswordFirstTime ? ForceChangePassword : Hour)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/timecard/hourspecification/:userId"
										component={availablePages.length !== 0 && availablePages.includes('timecard')
											? (isPasswordFirstTime ? ForceChangePassword : Hour)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/timecard/hourlyreport"
										component={availablePages.length !== 0 && availablePages.includes('timecard')
											? (isPasswordFirstTime ? ForceChangePassword : HourlyReports)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/clients/customerinfo/:clientId"
										component={availablePages.length !== 0 && availablePages.includes('clients')
											? (isPasswordFirstTime ? ForceChangePassword : CustomerInfo)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/clients/customerinfo/"
										component={availablePages.length !== 0 && availablePages.includes('clients')
											? (isPasswordFirstTime ? ForceChangePassword : CustomerInfo)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/clients/addcustomer"
										component={AddClientModal}
									/>
									<AuthRoute
										exact
										path="/orders/:route"
										component={availablePages.length !== 0 && availablePages.includes('orders')
											? (isPasswordFirstTime ? ForceChangePassword : OrderBar)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/orders/editorder/:orderId"
										component={availablePages.length !== 0 && availablePages.includes('orders')
											// ? Order
											? (isPasswordFirstTime ? ForceChangePassword : Order)
											: NotFound}
									/>

									<AuthRoute
										exact
										path="/billing/newbill"
										component={availablePages.length !== 0 && availablePages.includes('invoices')
											? (isPasswordFirstTime ? ForceChangePassword : NewBill)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/bills/bills/:id"
										component={availablePages.length !== 0 && availablePages.includes('invoices')
											? (isPasswordFirstTime ? ForceChangePassword : Invoice)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/billing/billable"
										component={availablePages.length !== 0 && availablePages.includes('invoices')
											? (isPasswordFirstTime ? ForceChangePassword : Billable)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/storage/warehousemanagement"
										component={availablePages.length !== 0 && availablePages.includes('storage')
											? (isPasswordFirstTime ? ForceChangePassword : MainStorage)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/reports/sales-statistics"
										component={availablePages.length !== 0 && availablePages.includes('reports')
											? (isPasswordFirstTime ? ForceChangePassword : SalesStatistics)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/reports/sales-printing"
										component={availablePages.length !== 0 && availablePages.includes('reports')
											? (isPasswordFirstTime ? ForceChangePassword : SalesPrinting)
											: NotFound}
									/>

									<AuthRoute
										exact
										path="/settings/company"
										component={availablePages.length !== 0 && availablePages.includes('settings')
											? (isPasswordFirstTime ? ForceChangePassword : Company)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/settings/pricelist"
										component={availablePages.length !== 0 && availablePages.includes('settings')
											? (isPasswordFirstTime ? ForceChangePassword : PriceList)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/settings/settings1"
										component={availablePages.length !== 0 && availablePages.includes('settings')
											? (isPasswordFirstTime ? ForceChangePassword : Settings)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/settings/työjonohaut"
										component={availablePages.length !== 0 && availablePages.includes('settings')
											? (isPasswordFirstTime ? ForceChangePassword : Työjonohaut)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/settings/users"
										component={availablePages.length !== 0 && availablePages.includes('settings')
											? (isPasswordFirstTime ? ForceChangePassword : Users)
											: NotFound}
									/>

									<AuthRoute
										exact
										path="/reports/ledgers"
										component={availablePages.length !== 0 && availablePages.includes('reports')
											? (isPasswordFirstTime ? ForceChangePassword : Ledgers)
											: NotFound}
									/>
									<AuthRoute
										exact
										path="/billing/preview/:billId"
										component={Preview}
									/>
									<AuthRoute
										exact
										path="/settings/users/usercustom/:userId"
										component={UserCustomPage}
									/>
									<Route exact path="/login/:companyId" component={Login}/>
									<Route exact path="/login" component={Login}/>
									<AuthRoute
										exact
										path="/pdf/:billId/:lang"
										component={PDFView}
									/>
									<AuthRoute
										exact
										path='/guide'
										component={Guide}
									/>
									<AuthRoute
										exact
										path='*'
										component={NotFound}
									/>
								</Switch>
							</CoreLayout>
						</Router>
					</ErrorBoundary>
				</Suspense>
			)
		}
	}
}
