import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close';
import './ModalButtons.css'

class ModalButtons extends Component {

	render() {
  	return (
      <div className="ModalButtons">
        <div className="modal-button-row">
          <div className="button close-button"
            onClick={() => this.props.handleClose()}>
            <div className="button-icon"><CloseIcon /></div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ModalButtons)
