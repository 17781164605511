import { CHANGE_RECORDING_FIELD } from '../../actions/actionTypes'
const initialState = {
	time: {
		today: 0,
		thisWeek: 0,
		lastWeek: 0,
		thisMonth: 0,
		lastMonth: 0
	},
	status: {
		checked: false
	},
	records: [],
	workingRecord: {},
	isWorking: false,
	report: []
}

const ReducerRecording = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_RECORDING_FIELD:
			return {
				...state,
				[action.field]: action.value
			}
		default:
			return state
	}
}

export default ReducerRecording
