import React from 'react'
import { Prompt } from 'react-router-dom'
import ModalWindow from './../ModalWindow/ModalWindow'
import { OnExitConfirmation } from './../Dialogs'
import { withTranslation } from 'react-i18next'

class RouteLeavingGuard extends React.Component {
    state = {
        modalVisible: false,
        lastLocation: this.props.location,
        confirmedNavigation: false,
    }
    showModal = (location) => this.setState({
        modalVisible: true,
        // lastLocation: location,
    })
    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = (nextLocation) => {
        // console.log(nextLocation)
        const { confirmedNavigation } = this.state
        const { shouldBlockNavigation } = this.props
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        // console.log(this.props)
        const { navigate } = this.props
        const { lastLocation } = this.state
        if (lastLocation) {
            this.setState({
                // confirmedNavigation: true
            }, () => {
                // // Navigate to the previous blocked location with your navigate function
                // navigate(lastLocation)
                navigate()
            })
        }
    })
    render() {
        // console.log(this.props, this.state)
        const { when, t } = this.props
        const { modalVisible } = this.state
        return (
            <>
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation} />
                <ModalWindow
                    width="modalWidth600"
                    open={modalVisible}
                    handleClose={() => this.closeModal(null)}
                >
                    <OnExitConfirmation
                        cancel={() => this.closeModal(null)}
                        accept={this.handleConfirmNavigationClick}
                        confirmationQuestion={t('orders.exitWithoutSaving')}
                        document={this.props.document}
                        warningMessage={t('orders.youMayLoseData')}
                    />
                </ModalWindow>
            </>
        )
    }
}
export default withTranslation()(RouteLeavingGuard)
