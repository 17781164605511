import { CHANGE_TIMECARD_FIELD, RESET_TIME } from '../../actions/actionTypes'
const initialState = {
	time: [],
	newDates: [],
	originTime: [], //For cancelling
	current: new Date(),
	weeks: {},
	editable: false,
	invalid: false
}

const ReducerTimeCard = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_TIMECARD_FIELD:
			return {
				...state,
				[action.field]: action.value
			}
		case RESET_TIME:
			return {
				...initialState,
				current: state.current
			}
		default:
			return state
	}
}

export default ReducerTimeCard
