import React from 'react'
import ModalWindow from './../ModalWindow/ModalWindow'
import { DeleteConfirmation } from './../Dialogs'

const DeleteHandler = ({modalVisible, closeModal, cancel, accept, confirmationQuestion, warningMessage}) => {

  return (
    <ModalWindow
      width="modalWidth600"
      open={modalVisible}
      handleClose={closeModal}
    >
      <DeleteConfirmation
        cancel={cancel}
        accept={accept}
        confirmationQuestion={confirmationQuestion}
        warningMessage={warningMessage}
      />
    </ModalWindow>
  )
}

export default DeleteHandler
