import {
	SET_CUSTOMERDATA,
	SET_USERDATA,
	HANDLE_SEARCH,
	CHANGE_CUSTOMERFIELD,
	MANAGE_CLICKS,
	SHOW_COMMENT,
	CHANGE_FIELD,
	COUNTS_CALC,
	CHANGE_FIELD_ADDCUSTOMER,
	SET_AS_DEFAULT_ADDCUSTOMER,
	CHANGE_FIELD_EDITINFO
} from '../../actions/actionTypes'

const initialState = {
	clicks: [true, false, false],
	comment: false,
	addComment: '',
	userData: [],
	originData: [],
	selectedCustomer: {},
	activeIndex: localStorage.getItem('clientIndex') || 0,
	isEditContract: false,
	newContract: {},
	isEditExistContrat: false,
	contractIndex: -1,
	showModal: false,
	isEditAddress: false,
	isNewAddress: false,
	newAddress: {},
	editAddressIndex: -1,
	countOrders: 0,
	countBills: 0,
	updateComment: '',
	addedEditedCustomer: {
		name: '',
		email: '',
		telephone: '',
		gsm: '',
		companyName: '',
		businessId: '',
		terms: '1',
		discount: 0,
		invoiceAddress: '',
		providerId: '',
		name1: '',
		name2: '',
		name3: '',
		deliveryAddress: '',
		zip: '',
		postOffice: '',
		country: '',
		additionalInfo: '',
		userData: [],
		customerNumber: 0,
		countries: [
			{
				type: 'businessid',
				name: 'clients.businessId',
				country: 'fi',
				countryFull: 'Finland',
				active: false
			},
			{
				type: 'gln',
				name: 'clients.gln',
				country: 'swe',
				countryFull: 'Sweden',
				active: false
			},
			{
				type: 'peppol',
				name: 'clients.peppol',
				country: 'swe',
				countryFull: 'Sweden',
				active: false
			}
		],
		businessIdTypes: [
			{
				type: 'businessid',
				name: 'clients.businessId',
				country: 'fi',
				countryFull: 'Finland',
				active: false
			}
		],
		businessIdStr: 'clients.businessId',
		companyCountry: 'Finland',
		businessIdType: 'businessid',
		activateCompany: false,
		activateCompanyThis: false,
		serviceId: '',
		einvoiceaddress: ''
	},
	activeClient: -1,
	showModalForm: false,
	editAddress: {},
	editContract: {},
	editGeneralInformation: {},
	showModalFormGeneralInformation: false,
	showModalAddClient: false,
	countries: [
		{
			type: 'businessid',
			name: 'clients.businessId',
			country: 'fi',
			countryFull: 'Finland',
			active: false
		},
		{
			type: 'gln',
			name: 'clients.gln',
			country: 'swe',
			countryFull: 'Sweden',
			active: false
		},
		{
			type: 'peppol',
			name: 'clients.peppol',
			country: 'swe',
			countryFull: 'Sweden',
			active: false
		}
	],
	businessIdTypes: [
		{
			type: 'businessid',
			name: 'clients.businessId',
			country: 'fi',
			countryFull: 'Finland',
			active: false
		}
	],
}
const ReducerCustomerInfo = (state = initialState, action) => {
	switch (action.type) {
		case SET_AS_DEFAULT_ADDCUSTOMER:
			return {
				...state,
				clicks: [true, false, false],
				comment: false,
				addComment: '',
				// originData: [],
				selectedCustomer: {},
				activeIndex: localStorage.getItem('clientIndex') || 0,
				isEditContract: false,
				newContract: {},
				isEditExistContrat: false,
				contractIndex: -1,
				showModal: false,
				isEditAddress: false,
				isNewAddress: false,
				newAddress: {},
				editAddressIndex: -1,
				countOrders: 0,
				countBills: 0,
				updateComment: '',
				addedEditedCustomer: {
					...state.addedEditedCustomer,
					name: '',
					email: '',
					telephone: '',
					gsm: '',
					companyName: '',
					businessId: '',
					terms: '1',
					discount: 0,
					invoiceAddress: '',
					providerId: '',
					name1: '',
					name2: '',
					name3: '',
					deliveryAddress: '',
					zip: '',
					postOffice: '',
					country: '',
					additionalInfo: '',
					userData: [],
					customerNumber: 0,
					businessIdTypes: [
						{
							type: 'businessid',
							name: 'clients.businessId',
							country: 'fi'
						}
					],
					businessIdStr: 'clients.businessId',
					companyCountry: 'Finland',
					businessIdType: 'businessid',
					activateCompany: false,
					activateCompanyThis: false,
					serviceId: '',
					einvoiceaddress: ''
				},
				activeClient: -1,
				showModalForm: false,
				editAddress: {},
				editContract: {},
				editGeneralInformation: {},
				showModalFormGeneralInformation: false,
				showModalAddClient: false,
			}
		case SET_CUSTOMERDATA: {
			return {
				...state,
				selectedCustomer: action.payload,
				isEditContract: false,
				isEditExistContrat: false,
				contractIndex: -1
			}
		}
		case SET_USERDATA:
			return { ...state, userData: action.payload, originData: action.payload }
		case CHANGE_CUSTOMERFIELD:
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					[action.field]: action.value
				}
			}
		case HANDLE_SEARCH:
			return { ...state, userData: action.payload }
		case MANAGE_CLICKS:
			return { ...state, clicks: action.payload }
		case SHOW_COMMENT:
			return { 
				...state, 
				comment: !state.comment,
				addComment: ''
			 }
		case CHANGE_FIELD:
			return {
				...state,
				[action.field]: action.value
			}
		case CHANGE_FIELD_ADDCUSTOMER:
			return {
				...state,
				addedEditedCustomer: {
					...state.addedEditedCustomer,
					[action.field]: action.value
				}
			}
		case CHANGE_FIELD_EDITINFO:
			return {
				...state,
				editGeneralInformation: {
					...state.editGeneralInformation,
					[action.field]: action.value
				}
			}
		case COUNTS_CALC:
			return {
				...state,
				countOrders: action.payload.countOrders,
				countBills: action.payload.countBills
			}
		default:
			return state
	}
}

export default ReducerCustomerInfo
