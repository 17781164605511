import React from 'react'
import { useTranslation } from 'react-i18next'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

const SearchGroup = ({ searchTerm, onChange }) => {
	const { t } = useTranslation()
	return (
		<ListItem
			style={{
				borderTop: '4px solid rgb(0, 125, 198)',
				padding: '15px',
				backgroundColor: '#FFF',
				borderRadius: '10px 10px 0 0'
			}}
		>
			<ListItemIcon style={{ minWidth: 0, paddingRight: 16 }}>
				<SearchIcon />
			</ListItemIcon>

			<InputBase
				fullWidth
				type="text"
				placeholder={t('storage.searchCategory')}
				inputProps={{ 'aria-label': 'search' }}
				value={searchTerm}
				onChange={onChange}
				style={{
					backgroundColor: 'rgb(253, 251, 251)',
					border: '1px solid rgba(0, 0, 0, 0.23)',
					borderRadius: 5,
					paddingLeft: 10,
					height: 40
				}}
			/>
		</ListItem>
	)
}

export default SearchGroup
