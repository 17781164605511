import React from 'react'
import ReactDOM from 'react-dom'
import './App.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import allReducers from './reducers/'
import './i18n'
import thunk from 'redux-thunk'

const isProduction = process.env.NODE_ENV === 'production'

const tools = [applyMiddleware(thunk)]
if (!isProduction && window.__REDUX_DEVTOOLS_EXTENSION__) {
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}
const store = createStore(allReducers, compose(...tools))

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
