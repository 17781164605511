export const checkDisabledJobs = (jobType, categoryJobTypes) => {
	let disabled = false
	if (
		jobType === 'Largeprint' &&
		(categoryJobTypes.includes('Businesscard') ||
			categoryJobTypes.includes('Print') ||
			categoryJobTypes.includes('Magazine') ||
			categoryJobTypes.includes('Book') ||
			categoryJobTypes.includes('File') ||
			categoryJobTypes.includes('Design') ||
			categoryJobTypes.includes('Product') ||
			categoryJobTypes.includes('Mailing') ||
			categoryJobTypes.includes('Binding'))
	)
		disabled = true
	else if (
		jobType !== 'Largeprint' &&
		// jobType !== 'Product' &&
		categoryJobTypes.includes('Largeprint')
	)
		disabled = true

	return disabled
}

export const checkDisabledColumns = (
	column,
	size,
	width,
	height,
	prodLength
) => {
	let disabled = false
	if (column === 'name') disabled = true
	if (
		!!size &&
		(column === 'width' || column === 'prodLength' || column === 'height')
	)
		disabled = true
	else if (column === 'size' && (!!width || !!height || !!prodLength))
		disabled = true

	return disabled
}
