import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: ''
		}
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	render() {
		if (this.state.hasError) {
			return <p>Loading failed! Please reload.</p>
		}

		return this.props.children
	}
}
export default ErrorBoundary
