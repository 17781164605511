import React, { useState } from 'react'
import SearchGroup from './SearchGroup'
import ProductGroupList from './ProductGroupList'
import ModalWindow from './../../../components/ModalWindow/ModalWindow'
import ModalButtons from '../../orders/Order/modals/ModalButtons'
import AddCategory from '../modals/AddCategory'
import EditCategory from '../modals/EditCategory'
import { EscapeHandler } from './../../../components/EventHandler'
import AddIcon from '@material-ui/icons/Add'
import { Button, Grid, List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const StorageSidebar = ({
	tableArray,
	onModifyCategory,
	onModifyProduct,
	modalOpen,
	modalClose,
	groupId,
	groupName,
	productId,
	actionName,
	columns,
	storageSettings,
	loadProductsByCat,
	productsArray,
	showProductsByCat,
	onShowProductsByCat,
	selectedColumnsArr,
	loadCategories,
	loading,
	jobTypesArray,
	category,
	product,
	onLoadingProducts,
	modalCancel
}) => {
	const { t } = useTranslation()

	const [isCancelConfirmation, setCancelConfirmation] = useState(false)
	const [isEdited, setEdited] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const onCancel = () => {
		isEdited ? setCancelConfirmation(true) : modalCancel()
	}

	const onModalClose = () => {
		setEdited(false)
		modalClose()
	}

	const closeCancelConfirmation = () => {
		setCancelConfirmation(false)
	}

	const onAcceptCancel = () => {
		setCancelConfirmation(false)
		setEdited(false)
		modalCancel()
	}

	const handleDataChanged = () => {
		setEdited(true)
	}

	const isSearched = searchTerm => {
		return function(tableArr) {
			if (typeof tableArr.name !== 'undefined') {
				return tableArr.name.toLowerCase().includes(searchTerm.toLowerCase())
			} else return {}
		}
	}
	const onSearchChange = event => {
		setSearchTerm(event.target.value)
	}

	if (loading) {
		return (
			<div style={{paddingBottom: 40}}>
				<List
					style={{
						borderRadius: '10px 10px 10px 10px ',
						paddingBottom: 0,
						paddingTop: 0
					}}
				>
					<SearchGroup value={searchTerm} onChange={onSearchChange} />
				</List>
				<div className="medium-loader"></div>
			</div>
		)
	} else {
		return (
			<Grid container>
				<EscapeHandler
					modalVisible={isCancelConfirmation}
					closeModal={closeCancelConfirmation}
					cancel={closeCancelConfirmation}
					accept={onAcceptCancel}
					document={t('orders.warning')}
					confirmationQuestion={t('orders.exitWithoutSaving')}
					warningMessage={t('orders.youMayLoseData')}
				/>

				<List
					style={{
						borderRadius: '10px 10px 10px 10px ',
						paddingBottom: 10,
						paddingTop: 0,
						position: 'relative',
						width: '100%'
					}}
				>
					<SearchGroup value={searchTerm} onChange={onSearchChange} />

					<ProductGroupList
						tableArray={tableArray}
						pattern={searchTerm}
						isSearched={isSearched}
						onModifyCategory={onModifyCategory}
						onModifyProduct={onModifyProduct}
						groupId={groupId}
						productId={productId}
						groupName={groupName}
						actionName={actionName}
						productsArray={productsArray}
						loadProductsByCat={loadProductsByCat}
						showProductsByCat={showProductsByCat}
						onShowProductsByCat={onShowProductsByCat}
						columns={columns}
						columnsArr={selectedColumnsArr}
						jobTypesArray={jobTypesArray}
						product={product}
					/>
				</List>

				<Grid container style={{ padding: 5 }}>
					<Button
						fullWidth
						variant="contained"
						fontSize="small"
						color="primary"
						onClick={() => onModifyCategory(groupId, (actionName = 'addCat'))}
						style={{
							margin: '15px',
							color: 'white',
							backgroundColor: '#007DC6'
						}}
					>
						<AddIcon />
						{t('storage.addgroup')}
					</Button>
				</Grid>

				<ModalWindow
					width="modalWidth900"
					handleClose={onCancel}
					open={modalOpen}
				>
					<ModalButtons handleClose={onCancel} />

					{actionName === 'addCat' && (
						<AddCategory
							handleClose={onModalClose}
							columns={columns}
							storageSettings={storageSettings}
							loadCategories={loadCategories}
							jobTypesArray={jobTypesArray}
							handleCancel={onCancel}
							handleDataChanged={handleDataChanged}
							isEdited={isEdited}
						/>
					)}
					{actionName === 'editCat' && (
						<EditCategory
							handleClose={onModalClose}
							groupId={groupId}
							category={category}
							columns={columns}
							storageSettings={storageSettings}
							onModifyCategory={onModifyCategory}
							actionName={actionName}
							loadCategories={loadCategories}
							jobTypesArray={jobTypesArray}
							handleCancel={onCancel}
							handleDataChanged={handleDataChanged}
							isEdited={isEdited}
							onLoadingProducts={onLoadingProducts}
						/>
					)}
				</ModalWindow>
			</Grid>
		)
	}
}

export default StorageSidebar
