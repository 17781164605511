import React, { Component } from 'react'
import './AddProduct.css'
import {
	RouteLeavingGuardSettings,
	EscapeHandler
} from './../../../components/EventHandler'
import ModalWindow from './../../../components/ModalWindow/ModalWindow'
import AddCustomSize from './../../settings/settings1/AddCustomSize'
import {
	Button,
	MenuItem,
	InputAdornment,
	Grid,
	Typography,
	TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import Currency from '../../../components/Currency/Currency'

import { fetchApi } from '../../../services/api'
import { jobTypes } from './../../orders/Order/jobs/jobTypes'
import { handleKeypress } from '../../../helpers/handleKeypress'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
	header: {
		padding: '3% 0 0 3%'
	},
	header1: {
		padding: '3% 0 3% 3%',
		minWidth: '75vw'
	},
	productTable: {
		width: '100%',
		overflow: 'auto',
		msOverflowStyle: 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	field: {},
	//paddingLeft: { paddingLeft: 10 },
	column: {
		padding: '0 20px 0 0',
		margin: '30px 0'
	},
	paper: {
		margin: '15px 0 15px 0'
	},
	menuPaper: {
		maxHeight: 'calc(100% - 150px)'
	},
	stickyMenuItem: {
		position: 'sticky',
		top: 0,
		backgroundColor: '#FFF',
		zIndex: 5
	},
	stickyMenuItemSafari: {
		position: '-webkit-sticky' /* Safari */
	},
	fontWeight600: {
		fontWeght: 600
	}
})

class AddProduct extends Component {
	constructor(props) {
		super(props)

		this.state = {
			groupId: '',
			productCode: '',
			name: '',
			additionalInformation: '',
			shelfLocation: '',
			sellingPrice: '',
			vat: '',
			vats: [],
			purchasePrice: '',
			unit: '',
			size: '',
			width: '',
			height: '',
			length: '',
			thickness: '',
			picture: '',
			pictureFile: '',
			inStock: '',
			reserved: '',
			sel: '0',
			taxFree: 0,
			taxInclude: 0,
			priceType: 'tax-free',
			errorGroup: false,
			errorName: false,
			errorSellPrice: false,
			errorPurchPrice: false,
			errorInStock: false,
			errorReserved: false,
			errorVat: false,
			errorWidth: false,
			wastePrice: '',
			measureUnit: 'mm',
			sizeSpecs: '',
			showTable: false,
			group: {
				name: ''
			},
			isAddingSize: false,
			printingJobs: [],
			newCustomSize: '',
			companySettingsSizes: [],
			companySettingsCustomSizes: [],
			prevCustomSizes: [],
			isCancelConfirmation: false
		}

		// console.log(this.props)
	}

	componentDidMount() {
		const { groupId } = this.props
		if (typeof groupId !== 'undefined' && groupId !== null && groupId !== 0) {
			const columnsArr = this.props.tableArray.filter(
				group => group._id === groupId
			)
			const group = columnsArr.length > 0 ? { ...columnsArr[0] } : {}

			const unit = group.jobTypes.includes('Largeprint') ? '\u33A1' : ''

			this.setState({
				group,
				groupId,
				showTable: true,
				unit
			})
		} else {
			this.setState({
				groupId: 0
			})
		}
		this.onLoadCompanyData().then(resolve => {})
		this.onLoadCompanySettings().then(resolve => this.createPrintingJobsArray())
	}

	onLoadCompanyData = async () => {
		try {
			const response = await fetchApi('/api/v1/companies/companies', 'GET')
			if (response.status === 200) {
				const { data } = response.data
				// const arr = data.vat.filter(value => {
				// 	return value.selected === true;
				// })
				const arr = data.vat.sort((vat1, vat2) => {
					return vat1.selected === vat2.selected ? 0 : vat1.selected ? -1 : 1
				})
				if (arr.length !== 0) {
					this.setState({
						vat: arr[0].value,
						vats: arr
					})
				}
			}

			if (response.status === 204) {
				console.info('status 204')
				return null
			}
		} catch (err) {
			console.error(err.message)
			return err.message
		}
	}

	handleInputChange = async event => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name
		if (value === 'custom') {
			this.setState({
				isAddingSize: true
			})
		} else {
			this.setState({
				[name]: value,
				size: value.name
				// isEdited: true
			})
			this.props.handleDataChanged(true)
		}
	}

	selectGroup = (event, group) => {
		if (typeof group !== 'undefined' && group !== null) {
			const unit = group.jobTypes?.includes('Largeprint') ? '\u33A1' : ''

			this.setState({
				group,
				errorGroup: false,
				groupId: group._id,
				showTable: true,
				unit
			})
		} else {
			this.setState({
				group: {
					name: ''
				},
				errorGroup: false,
				groupId: '',
				showTable: false
			})
		}
	}

	// Set selling price type
	selectBox = event => {
		let key = ''
		if (event.target.value === '0') {
			key = 'tax-free'
		} else {
			key = 'tax-include'
		}
		this.setState(
			{
				sel: event.target.value,
				priceType: key
			},
			() => {
				this.calculate()
			}
		)
	}

	transformToFixedDecimals = (e, field) => {
		e.preventDefault()
		const value = this.state[field]
		const decimals = 4
		const newValue = parseFloat(value).toFixed(decimals)
		this.setState(
			{
				[field]: newValue
			},
			() => {
				if (field === 'sellingPrice') this.calculate()
			}
		)
	}

	setSelling = e => {
		this.setState(
			{
				sellingPrice: e.target.value,
				errorSellPrice: false
			},
			() => {
				this.calculate()
			}
		)
		this.props.handleDataChanged(true)
	}

	setVat = e => {
		this.setState({ vat: e.target.value }, () => {
			this.calculate()
		})
		this.props.handleDataChanged(true)
	}

	calculate = () => {
		const vat = parseFloat(this.state.vat) || 0
		const price = parseFloat(this.state.sellingPrice) || 0
		let taxFreePrice = 0
		let taxIncludedPrice = 0
		// set price excluded VAT
		if (this.state.sel === '0') {
			taxFreePrice = price
			taxIncludedPrice = (price * (1 + vat / 100)).toFixed(4)
		} else {
			// set price included VAT
			taxFreePrice = (price / (1 + vat / 100)).toFixed(4)
			taxIncludedPrice = price
		}
		this.setState({
			taxFree: taxFreePrice,
			taxInclude: taxIncludedPrice
		})
	}

	getImage = e => {
		this.setState({
			picture: e.target.value,
			pictureFile: e.target.files[0]
		})
		this.props.handleDataChanged(true)
	}

	validateFields = e => {
		e.preventDefault()
		let countErrors = 0

		if (
			this.state.groupId === 0 ||
			this.state.groupId === '' ||
			typeof this.state.groupId === 'undefined'
		) {
			countErrors += 1
			this.setState({
				errorGroup: true
			})
		}

		if (this.state.name === '') {
			countErrors += 1
			this.setState({
				errorName: true
			})
		}

		if (this.state.sellingPrice < 0) {
			countErrors += 1
			this.setState({
				errorSellPrice: true
			})
		}

		if (this.state.purchasePrice < 0) {
			countErrors += 1
			this.setState({
				errorPurchPrice: true
			})
		}
		if (this.state.wastePrice < 0) {
			countErrors += 1
			this.setState({
				errorWastePrice: true
			})
		}

		if (this.state.inStock < 0) {
			countErrors += 1
			this.setState({
				errorInStock: true
			})
		}

		if (this.state.reserved < 0) {
			countErrors += 1
			this.setState({
				errorReserved: true
			})
		}
		if (typeof this.state.vat === 'undefined' || this.state.vat === '') {
			countErrors += 1
			this.setState({
				errorVat: true
			})
		}
		if (this.state.group.jobTypes.includes('Largeprint') && !this.state.width) {
			countErrors += 1
			this.setState({
				errorWidth: true
			})
		}

		if (countErrors === 0) {
			this.createProduct()
		} else return null
	}

	createProduct = async () => {
		const vatIdArr = this.state.vats.filter(item => {
			return item.value === this.state.vat
		})
		const vatId = vatIdArr[0]._id
		try {
			const payload = {
				group: this.state.groupId,
				productCode: this.state.productCode,
				name: this.state.name,
				additionalInformation: this.state.additionalInformation,
				inStock: this.state.inStock,
				reserved: this.state.reserved,
				unit: this.state.unit,
				sellingPrice: this.state.taxFree,
				vat: vatId,
				purchasePrice: this.state.purchasePrice,
				shelfLocation: this.state.shelfLocation,
				picture: this.state.picture,
				size: this.state.size,
				width: this.state.width,
				height: this.state.height,
				prodLength: this.state.length,
				thickness: this.state.thickness,
				priceType: 'tax-free',
				wastePrice: this.state.wastePrice,
				sizeSpecs: this.state.sizeSpecs,
				measureUnit: this.state.measureUnit
			}
			// console.log('payload: ', payload)

			const response = await fetchApi(
				'/api/products/createProduct',
				'POST',
				payload
			)
			if (response.status === 200) {
				this.props.handleClose()
			}
			if (response.status === 204) {
				console.info('status 204')
				return null
			}
		} catch (err) {
			console.error('createProduct', err.message)
		}
	}

	onExitAddingSize = () => {
		if (this.props.isEdited) {
			this.setState({
				isCancelConfirmation: true
			})
		} else {
			this.setState({
				isAddingSize: false
			})
		}
	}

	closeAddingSize = () => {
		this.setState({
			isCancelConfirmation: false,
			isAddingSize: false
		})
		this.props.handleDataChanged(false)
	}
	closeCancelConfirmation = () => {
		this.setState({
			isCancelConfirmation: false
		})
	}

	handleChange = e => {
		const target = e.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name
		let errorField = ''
		if (name === 'name') {
			errorField = 'errorName'
		}
		if (name === 'purchasePrice') {
			errorField = 'errorPurchPrice'
		}
		if (name === 'wastePrice') {
			errorField = 'errorWastePrice'
		}
		if (name === 'inStock') {
			errorField = 'errorInStock'
		}
		if (name === 'reserved') {
			errorField = 'errorReserved'
		}
		if (name === 'vat') {
			errorField = 'errorVat'
		}
		if (name === 'width') {
			errorField = 'errorWidth'
		}

		this.setState({
			[name]: value,
			[errorField]: false
			// isEdited: true
		})
		this.props.handleDataChanged(true)
	}

	onLoadCompanySettings = async () => {
		const { isAddingSize } = this.state
		try {
			const response = await fetchApi('/api/v1/companies/companies', 'GET')
			// console.log('companyResponse', response.status)
			if (response.status === 200) {
				// console.log(response.data)
				const { data } = response.data
				const { customSizes, sizes } = data.settings
				// console.log(customSizes, sizes)
				let value = {}
				let obj = {}
				let arr = []
				customSizes.forEach(size => {
					value = {
						name: size.name,
						width: size.width,
						height: size.height,
						jobTypes: size.jobTypes
					}
					obj = {
						value: value,
						_id: size._id
					}
					arr.push(obj)
				})

				const prevCustomSizes = { ...this.state.companySettingsCustomSizes }
				await new Promise(resolve => {
					this.setState(
						{
							company: data,
							companySettingsSizes: sizes,
							prevCustomSizes,
							companySettingsCustomSizes: arr,
							namePaperSize: '',
							widthPaperSize: '',
							heightPaperSize: ''
						},
						() => resolve()
					)
				})

				if (isAddingSize) {
					this.setAddedCustomSize()
				}
			}

			if (response.status === 204) {
				console.info('status 204')
				return null
			}

			return response
		} catch (err) {
			console.error(err.message)
			return err.message
		}
	}

	createPrintingJobsArray = () => {
		const { companySettingsSizes, companySettingsCustomSizes } = this.state

		const printingJobs = Object.entries(jobTypes)
			.filter(jobType => {
				return jobType[1].printingJobs === true
			})
			.map(jobType => {
				const defaultSizes = companySettingsSizes
					.filter(size => {
						return size.value.jobTypes.includes(jobType[0])
					})
					.map(size => {
						return size.value.name
					})
				const customSizes = companySettingsCustomSizes
					.filter(size => {
						return size.value.jobTypes.includes(jobType[0])
					})
					.map(size => {
						return size.value.name
					})
				const sizes = defaultSizes.concat(customSizes)
				return {
					name: jobType[0],
					title: jobType[1].title,
					sizes: sizes
				}
			})
		this.setState({
			printingJobs,
			isAddingSize: false
		})
	}

	setAddedCustomSize = () => {
		const { companySettingsCustomSizes, prevCustomSizes } = this.state
		let newCustomSize = ''
		const index = companySettingsCustomSizes.length - 1
		if (prevCustomSizes.length !== companySettingsCustomSizes.length) {
			newCustomSize = companySettingsCustomSizes[index]
		}
		this.setState({
			sizeSpecs: newCustomSize.value
		})
	}

	render() {
		const { classes, t, history, isEdited } = this.props
		const {
			vats,
			companySettingsSizes,
			companySettingsCustomSizes,
			showTable,
			isAddingSize,
			group
		} = this.state
		const { columns } = this.state.group

		return (
			<div className="AddProduct">
				<h1 className="modal-h1">{t('storage.newProduct')}</h1>

				{/* Select category */}
				<div className="input-row">
					<div className="input-content">
						{this.props.tableArray.length > 0 ?
							<Autocomplete
								value={this.state.group}
								onChange={(e, newValue) => this.selectGroup(e, newValue)}
								id="group-autocomplete"
								name="group"
								options={this.props.tableArray}
								getOptionLabel={group => group.name}
								renderInput={params => (
									<TextField
										{...params}
										label={t('storage.selectCategory')}
										fullWidth
										error={this.state.errorGroup}
										variant="outlined"
									/>
								)}
								style={{ width: '100%' }}
							/>
							:
							alert(t('storage.noCategory'))
						}
					</div>
				</div>

				<Grid container style={{ paddingBottom: 5 }}>

					{showTable && (
						<Grid container className={classes.productTable}>
							<Grid
								container
								item
								xs={12}
								direction="row"
								id="table"
							>
								{/* General Info and Sizes */}
								<Grid
									container
									item
									xs={12}
									md={6}
									direction="column"
									className={classes.column}
									style={{ borderRight: '1px solid #dfdfdf' }}
									id="general-info"
								>
									{/* Product Code and Name */}
									<Grid item id="product">
										{columns.productCode && (
											<Grid item sm={6} xs={12}>
												<TextField
													className={classes.field}
													error={this.state.errorCode}
													label={t('storage.productCode')}
													name="productCode"
													value={this.state.productCode}
													margin="normal"
													fullWidth
													variant="outlined"
													onChange={this.handleChange}
												/>
											</Grid>
										)}

										{columns.name && (
											<Grid item xs={12}>
												<TextField
													className={classes.field}
													required
													error={this.state.errorName}
													label={t('storage.productName')}
													name="name"
													value={this.state.name}
													margin="normal"
													fullWidth
													variant="outlined"
													onChange={this.handleChange}
												/>
											</Grid>
										)}
										{/* </Grid> */}
										{columns.additionalInformation && (
											<Grid item xs={12}>
												<TextField
													className={classes.field}
													fullWidth
													label={t('storage.productDesc')}
													name="additionalInformation"
													value={this.state.additionalInformation}
													margin="normal"
													variant="outlined"
													onChange={this.handleChange}
													inputProps={{ maxLength: 40 }}
												/>
											</Grid>
										)}
									</Grid>

									<Grid item id="storage-info">
										{/* Shelf Location */}
										{columns.shelfLocation && (
											<Grid item sm={6} xs={12}>
												<TextField
													className={classes.field}
													fullWidth
													label={t('storage.location')}
													value={this.state.shelfLocation}
													name="shelfLocation"
													margin="normal"
													variant="outlined"
													onChange={this.handleChange}
												/>
											</Grid>
										)}

										{/* Storage Unit  */}
										{columns.unit ? (
											group.jobTypes?.includes('Largeprint') ? (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														fullWidth
														label={t('storage.unit')}
														name="unit"
														value={this.state.unit}
														margin="normal"
														variant="outlined"
														disabled
													/>
												</Grid>
											) : (
												<Grid item sm={6} xs={12}>
													<TextField
														select
														className={classes.field}
														fullWidth
														label={t('storage.unit')}
														name="unit"
														value={this.state.unit}
														margin="normal"
														variant="outlined"
														onChange={this.handleChange}
													>
														<MenuItem value={'pc'}>{t('orders.pc')}</MenuItem>
														<MenuItem value={'mm'}>mm</MenuItem>
														<MenuItem value={'cm'}>cm</MenuItem>
														<MenuItem value={'m'}>m</MenuItem>
														<MenuItem
															value={'\u33A1'}
															className={classes.fontWeight600}
														>
															{'\u33A1'}
														</MenuItem>
													</TextField>
												</Grid>
											)
										) : null}

										<Grid item>

											{/* In Stock  */}
											{columns.inStock && (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														label={`${t('storage.available')} ${
															this.state.unit !== '' ? '(' : ''
														} ${
															this.state.unit === 'pc'
																? `${t('orders.pc')}`
																: this.state.unit
														} ${this.state.unit !== '' ? ')' : ''}`}
														error={this.state.errorInStock}
														value={this.state.inStock}
														type="number"
														name="inStock"
														InputProps={{
															inputProps: {
																min: 0
															}
														}}
														margin="normal"
														fullWidth
														variant="outlined"
														onChange={this.handleChange}
													/>
												</Grid>
											)}

											{/* Reserved  */}
											{columns.reserved && (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														error={this.state.errorReserved}
														label={`${t('storage.reserved')} ${
															this.state.unit !== '' ? '(' : ''
														} ${
															this.state.unit === 'pc'
																? `${t('orders.pc')}`
																: this.state.unit
														} ${this.state.unit !== '' ? ')' : ''}`}
														name="reserved"
														value={this.state.reserved}
														type="number"
														InputProps={{
															inputProps: {
																min: 0
															}
														}}
														margin="normal"
														fullWidth
														variant="outlined"
														onChange={this.handleChange}
													/>
												</Grid>
											)}
										</Grid>
									</Grid>

									{/* Picture */}
									{columns.picture && (
										<Grid item id="picture">
											<TextField
												className={classes.field}
												margin="normal"
												fullWidth
												name="picture"
												variant="outlined"
												type="file"
												accept=".jpg, .png"
												onChange={e => {
													this.getImage(e)
												}}
											/>
										</Grid>
									)}

									{columns.size && (
										<Grid item id="paper-size">

											{/* Paper size name  */}
											<Grid item sm={6} xs={12}>
												<TextField
													type="text"
													select
													fullWidth
													label={t('storage.paperSize')}
													name="sizeSpecs"
													value={this.state.sizeSpecs || ''}
													onChange={this.handleInputChange}
													inputProps={{
														autoWidth: true
													}}
													SelectProps={{
														MenuProps: {
															classes: {
																paper: classes.menuPaper
															},
															PaperProps: {
																style: {
																	transform: 'translateX(50%) translateY(-10%)'
																}
															}
														}
													}}
													margin="normal"
													variant="outlined"
												>
													<MenuItem
														value="custom"
														className={`${classes.stickyMenuItem} ${classes.stickyMenuItemSafari}`}
														divider
														component="button"
													>
														<Typography variant="h6" color="primary">
															{t('storage.addSize')}
														</Typography>
													</MenuItem>

													<Typography variant="h6" style={{ paddingLeft: 15 }}>
														{t('settings.defaultSizes')}
													</Typography>

													{typeof companySettingsSizes !== 'undefined' &&
													companySettingsSizes.length > 0 ? (
														companySettingsSizes.map((value, index) => {
															return (
																<MenuItem key={index} value={value.value}>
																	{value.value.name}
																</MenuItem>
															)
														})
													) : (
														<MenuItem disabled>{t('settings.noSizes')}</MenuItem>
													)}

													<Typography variant="h6" style={{ paddingLeft: 15 }}>
														{t('settings.customSizes')}
													</Typography>

													{typeof companySettingsCustomSizes !== 'undefined' &&
													companySettingsCustomSizes.length > 0 ? (
														companySettingsCustomSizes.map(size => (
															<MenuItem key={size._id} value={size.value}>
																{size.value.name}
															</MenuItem>
														))
													) : (
														<MenuItem disabled>{t('settings.noSizes')}</MenuItem>
													)}
												</TextField>
											</Grid>

											{/* Paper Width and Height  */}
											<Grid item >
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														type="number"
														label={`${t('orders.width')} (mm)`}
														value={this.state.sizeSpecs.width}
														disabled
														InputLabelProps={{
															shrink: true
														}}
														margin="normal"
														variant="outlined"
													/>
												</Grid>
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														type="number"
														label={`${t('orders.height')} (mm)`}
														value={this.state.sizeSpecs.height}
														disabled
														InputLabelProps={{
															shrink: true
														}}
														margin="normal"
														variant="outlined"
													/>
												</Grid>
											</Grid>
										</Grid>
									)}

									<ModalWindow
										width="modalWidth600"
										open={isAddingSize}
										handleClose={this.onExitAddingSize}
									>
										<>
											<Grid container justify="flex-end">
												<Button onClick={this.onExitAddingSize}>X</Button>
											</Grid>
											<AddCustomSize
												printingJobs={this.state.printingJobs}
												onAddingSize={this.props.handleDataChanged}
												onLoadCompanySettings={this.onLoadCompanySettings}
												createPrintingJobsArray={this.createPrintingJobsArray}
												customSizes={this.state.companySettingsCustomSizes}
												selectedJobs={group.jobTypes}
											/>
										</>
									</ModalWindow>

									{/* Product Thickness */}
									{columns.thickness && (
										<Grid item>
											<Grid item sm={6} xs={12}>
												<TextField
													className={classes.field}
													label={`${t('storage.thickness')} (g)`}
													name="thickness"
													type="number"
													value={this.state.thickness}
													margin="normal"
													fullWidth
													variant="outlined"
													InputProps={{
														inputProps: {
															min: 0
														}
													}}
													onChange={this.handleChange}
												/>
											</Grid>
										</Grid>
									)}
									{!columns.size && (
										<Grid item id="product-size">

											{/* Measure Unit */}
											{(!!columns.width ||
												!!columns.height ||
												!!columns.prodLength) && (
												<Grid item sm={6} xs={12}>
													<TextField
														select
														label={`${t('storage.measureUnit')}`}
														name="measureUnit"
														type="string"
														value={this.state.measureUnit}
														margin="normal"
														fullWidth
														variant="outlined"
														onChange={this.handleChange}
													>
														<MenuItem value={'mm'}>mm</MenuItem>
														<MenuItem value={'m'}>m</MenuItem>
													</TextField>
												</Grid>
											)}

											{/* Product Width  */}
											{columns.width && (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														type="number"
														label={`${t('orders.width')} ${
															this.state.measureUnit !== ''
																? `(${this.state.measureUnit})`
																: ''
														}`}
														name="width"
														value={this.state.width}
														onChange={this.handleChange}
														margin="normal"
														fullWidth
														variant="outlined"
														required={group.jobTypes.includes('Largeprint')}
														error={this.state.errorWidth}
													/>
												</Grid>
											)}

											{/* Product Height  */}
											{columns.height && (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														type="number"
														label={`${t('orders.height')} ${
															this.state.measureUnit !== ''
																? `(${this.state.measureUnit})`
																: ''
														}`}
														name="height"
														value={this.state.height}
														onChange={this.handleChange}
														margin="normal"
														fullWidth
														variant="outlined"
													/>
												</Grid>
											)}

											{/* Product Length  */}
											{columns.prodLength && (
												<Grid item sm={6} xs={12}>
													<TextField
														className={classes.field}
														type="number"
														label={`${t('storage.length')} ${
															this.state.measureUnit !== ''
																? `(${this.state.measureUnit})`
																: ''
														}`}
														name="length"
														value={this.state.length}
														onChange={this.handleChange}
														margin="normal"
														fullWidth
														variant="outlined"
													/>
												</Grid>
											)}
										</Grid>
									)}
								</Grid>

								{/* Price Info  */}
								<Grid
									container
									item
									xs={12}
									md={5}
									direction="column"
									className={classes.column}
									style={{ marginLeft: '20px', boxSizing: 'border-box' }}
									id="acounting"
								>
									<Grid item id="prices">

										{/* Selling Price  */}
										{columns.sellingPrice && (
											<Grid item sm={8} xs={12}>
												<TextField
													className={classes.field}
													error={this.state.errorSellPrice}
													id="hinta"
													name="sellingPrice"
													label={`${t('storage.selling')} ${
														this.state.unit !== '' ? '/' : ''
													} ${
														this.state.unit === 'pc'
															? `${t('orders.pc')}`
															: this.state.unit
													} `}
													type="number"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<Currency />
															</InputAdornment>
														)
													}}
													margin="normal"
													variant="outlined"
													value={this.state.sellingPrice}
													onChange={e => this.setSelling(e)}
													onKeyDown={handleKeypress}
													onBlur={e =>
														this.transformToFixedDecimals(e, 'sellingPrice')
													}
												/>
											</Grid>
										)}

										{columns.vat && (
											<Grid item sm={8} xs={12}>
												<TextField
													select
													className={classes.field}
													id="vat"
													name="vat"
													error={this.state.errorVat}
													label={t('storage.vat')}
													margin="normal"
													variant="outlined"
													value={this.state.vat}
													onChange={e => this.setVat(e)}
												>
													{typeof vats !== 'undefined'
														? vats.map(item => {
																return (
																	<MenuItem
																		style={{ cursor: 'pointer' }}
																		value={item.value}
																		key={item._id}
																	>
																		{item.value}%
																	</MenuItem>
																)
														  })
														: null}
												</TextField>
											</Grid>
										)}
									</Grid>

									{columns.sellingPrice && (
										<Grid
											item
											id="alv"
										>

											{/* Price Type  */}
											<Grid item sm={8} xs={12}>
												<TextField
													className={classes.field}
													select
													fullWidth
													label={t('storage.type')}
													name="hintatyyppi"
													id="hintatyyppi"
													value={this.state.sel}
													onChange={this.selectBox}
													margin="normal"
													variant="outlined"
												>
													<MenuItem value={'0'}>{t('storage.taxFree')}</MenuItem>
													<MenuItem value={'1'}>{t('storage.taxInc')}</MenuItem>
												</TextField>
											</Grid>

											<Grid container direction="column" item xs>

												{/* Price excluded VAT  */}
												<Grid item sm={8} xs={12}>
													<TextField
														className={classes.field}
														style={{ backgroundColor: '#e0e0e0' }}
														label={`${t('storage.taxFree')} ${
															this.state.unit !== '' ? '/' : ''
														} ${
															this.state.unit === 'pc'
																? `${t('orders.pc')}`
																: this.state.unit
														} `}
														InputLabelProps={{ shrink: true }}
														name="tax-free-price"
														margin="normal"
														fullWidth
														InputProps={{
															readOnly: true
														}}
														variant="outlined"
														id="veroton"
														value={parseFloat(this.state.taxFree).toFixed(4)}
													/>
												</Grid>

												{/* Price included VAT  */}
												<Grid item sm={8} xs={12}>
													<TextField
														className={classes.field}
														style={{ backgroundColor: '#e0e0e0' }}
														label={`${t('storage.taxInc')} ${
															this.state.unit !== '' ? '/' : ''
														} ${
															this.state.unit === 'pc'
																? `${t('orders.pc')}`
																: this.state.unit
														} `}
														InputLabelProps={{ shrink: true }}
														name="price-with-tax"
														margin="normal"
														fullWidth
														InputProps={{
															readOnly: true
														}}
														variant="outlined"
														id="verollinen"
														value={parseFloat(this.state.taxInclude).toFixed(4)}
													/>
												</Grid>
											</Grid>
										</Grid>
									)}

									<Grid
										item
										id="other-prices"
									>

										{/* Purchase Price  */}
										{columns.purchasePrice && (
											<Grid item sm={8} xs={12}>
												<TextField
													className={classes.field}
													error={this.state.errorPurchPrice}
													label={`${t('storage.purchase')}, ${t(
														'storage.tax-free'
													)} ${this.state.unit !== '' ? '/' : ''} ${
														this.state.unit === 'pc'
															? `${t('orders.pc')}`
															: this.state.unit
													} `}
													name="purchasePrice"
													type="number"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<Currency />
															</InputAdornment>
														)
													}}
													margin="normal"
													fullWidth
													variant="outlined"
													value={this.state.purchasePrice}
													onChange={this.handleChange}
													onKeyDown={handleKeypress}
													onBlur={e =>
														this.transformToFixedDecimals(e, 'purchasePrice')
													}
												/>
											</Grid>
										)}

										{/* Waste Price  */}
										{columns.wastePrice && (
											<Grid item sm={8} xs={12}>
												<TextField
													className={classes.field}
													error={this.state.errorWastePrice}
													id="waste-paper-peice"
													name="wastePrice"
													label={`${t('storage.wastePrice')}, ${t(
														'storage.tax-free'
													)} ${this.state.unit !== '' ? '/' : ''} ${
														this.state.unit === 'pc'
															? `${t('orders.pc')}`
															: this.state.unit
													}`}
													type="number"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<Currency />
															</InputAdornment>
														)
													}}
													margin="normal"
													fullWidth
													variant="outlined"
													value={this.state.wastePrice}
													onChange={this.handleChange}
													onKeyDown={handleKeypress}
													onBlur={e =>
														this.transformToFixedDecimals(e, 'wastePrice')
													}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}

					<EscapeHandler
						modalVisible={this.state.isCancelConfirmation}
						closeModal={this.closeCancelConfirmation}
						cancel={this.closeCancelConfirmation}
						accept={this.closeAddingSize}
						document={t('orders.warning')}
						confirmationQuestion={t('orders.exitWithoutSaving')}
						warningMessage={t('orders.youMayLoseData')}
					/>

					<RouteLeavingGuardSettings
						// When should shouldBlockNavigation be invoked,
						// simply passing a boolean
						// (same as "when" prop of Prompt of React-Router)
						when={isEdited}
						// Navigate function
						navigate={path => history.push(path)}
						// location={location}
						document={t('orders.warning')}
						// Use as "message" prop of Prompt of React-Router
						shouldBlockNavigation={location => {
							// This case it blocks the navigation when:
							// the user is editing job.
							if (isEdited) {
								return true
							}
							return false
						}}
					/>
				</Grid>

				<div className="modal-buttons">
					<div
						className="btn-33 goback-button"
						onClick={this.props.handleCancel}
					>
						<div className="button-icon">
							<CancelIcon />
						</div>
						<div className="button-text">
							{t('orders.cancel')}
						</div>
					</div>

					{showTable ?
						<div
							className="btn-33 edit-button"
							onClick={this.validateFields}
						>
							<div className="button-icon">
								<SaveIcon />
							</div>
							<div className="button-text">
								{t('orders.save')}
							</div>
						</div>
					: null}
				</div>

			</div>
		)
	}
}

export default withRouter(withTranslation()(withStyles(styles)(AddProduct)))
