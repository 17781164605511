import { CHANGE_WORKORDER_FIELD } from './actionTypes'

export const changeField = (field, value) => ({
	type: CHANGE_WORKORDER_FIELD,
	field,
	value
})

export const changeBody = (field, value) => ({
	type: 'CHANGE_BODY_FIELD',
	field,
	value
})
