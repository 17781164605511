import React, { Component } from 'react'
import './EditCategory.css'
import '../../orders/Order/modals/ModalInputs.css'

import { DeleteHandler } from './../../../components/EventHandler'
import {
	TextField,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'

import { checkDisabledColumns, checkDisabledJobs } from '../utils/GroupManagementHelpers'
import { fetchApi } from '../../../services/api'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
	cell: {
		display: 'block',
		position: 'relative',
		maxWidth: '220px',
		float: 'left'
	},
})

class EditCategory extends Component {
	constructor(props) {
		super(props)

		this.state = {
			nameCat: '',
			inventoryMonitoring: false,
			storageUnits: false,
			productCode: false,
			name: false,
			additionalInformation: false,
			inStock: false,
			reserved: false,
			unit: false,
			sellingPrice: false,
			vat: false,
			purchasePrice: false,
			shelfLocation: false,
			picture: false,
			size: false,
			width: false,
			prodLength: false,
			height: false,
			thickness: false,
			actionName: 'deleteCat',
			errorGroupName: false,
			wastePrice: false,
			jobTypes: [],
			isDeleteModalOpen: false,
			// disabledColumns: [],
		}
	}

	componentDidMount() {
		const quObj = this.props.category()

		if (quObj.columns) {
			const { width, height, prodLength, size } = quObj.columns
			const disabledColumns = []
			let isChecked = { width, height, prodLength }
			if (typeof size !== 'undefined' && size) {
				disabledColumns.push('height', 'width', 'prodLength')
				isChecked.width = false
				isChecked.height = false
				isChecked.prodLength = false
			}
			this.setState({
				groupId: quObj._id,
				nameCat: quObj.name,
				inventoryMonitoring: quObj.inventoryMonitoring,
				storageUnits: quObj.storageUnits,
				disabledColumns,
				productCode: quObj.columns.productCode,
				name: quObj.columns.name,
				additionalInformation: quObj.columns.additionalInformation,
				inStock: quObj.columns.inStock,
				reserved: quObj.columns.reserved,
				unit: quObj.columns.unit,
				sellingPrice: quObj.columns.sellingPrice,
				vat: quObj.columns.vat,
				purchasePrice: quObj.columns.purchasePrice,
				shelfLocation: quObj.columns.shelfLocation,
				picture: quObj.columns.picture,
				size: quObj.columns.size,
				height: isChecked.height,
				width: isChecked.width,
				prodLength: isChecked.prodLength,
				thickness: quObj.columns.thickness,
				wastePrice: quObj.columns.wastePrice,
				jobTypes: quObj.jobTypes || []
			})
		}
	}

	onEdit = async () => {
		if (
			this.state.nameCat === '' ||
			typeof this.state.nameCat === 'undefined'
		) {
			this.setState({
				errorGroupName: true
			})
			return
		}
		this.props.onLoadingProducts()
		try {
			const { groupId, nameCat } = this.state
			let payload = {
				groupId: groupId,
				name: nameCat,
				inventoryMonitoring: this.state.inventoryMonitoring,
				storageUnits: this.state.storageUnits,
				columns: {
					productCode: this.state.productCode,
					name: this.state.name,
					additionalInformation: this.state.additionalInformation,
					inStock: this.state.inStock,
					reserved: this.state.reserved,
					unit: this.state.unit,
					sellingPrice: this.state.sellingPrice,
					vat: this.state.vat,
					purchasePrice: this.state.purchasePrice,
					shelfLocation: this.state.shelfLocation,
					picture: this.state.picture,
					size: this.state.size,
					width: this.state.width,
					height: this.state.height,
					prodLength: this.state.prodLength,
					thickness: this.state.thickness,
					wastePrice: this.state.wastePrice,
				},
				jobTypes: this.state.jobTypes
			}

			const response = await fetchApi('/api/groups/editGroup', 'POST', payload)
			if (response.status === 200) {
				await this.props.loadCategories("updated", groupId, nameCat)
				this.props.handleClose()
			}
			if (response.status === 204) {
				console.log('status 204')
				return null
			}
		} catch (err) {
			console.error(err.message)
		}
	}

	onEditCategoryName = (e) => {
		this.setState({
			nameCat: e.target.value,
			helperText: '',
			errorGroupName: false,
		})
		this.props.handleDataChanged(true)
	}

	onSelectProperty = (e, property) => {
		const { 
			width,
			height,
			prodLength,
			size,
			sellingPrice,
			wastePrice 
		} = this.state
		const { checked } = e.target
		let isChecked = {
			width,
			height,
			prodLength,
			size,
			sellingPrice,
			wastePrice,
		}
		if (property === 'size') {
			if (checked) {
				isChecked.width = false
				isChecked.height = false
				isChecked.prodLength = false
				isChecked.size = true
			} else isChecked.size = false
		} else if (property === 'width') isChecked.width = checked
		else if (property === 'height') isChecked.height = checked
		else if (property === 'prodLength') isChecked.prodLength = checked
	
		else if (property === 'sellingPrice') {
			isChecked.sellingPrice = checked
		} 
		else if (property === 'wastePrice') {
			isChecked.wastePrice = checked
		} 
		else if (size === true) {
			isChecked.width = false
			isChecked.height = false
			isChecked.prodLength = false
		} 

		this.setState({
			[property]: checked,
			height: isChecked.height,
			width: isChecked.width,
			prodLength: isChecked.prodLength,
			size: isChecked.size,
			sellingPrice: isChecked.sellingPrice,
			wastePrice: isChecked.wastePrice
		})
		this.props.handleDataChanged(true)
	}

	onSelectJob = (e) => {
		const { value, checked } = e.target
		if (checked === true) {
			this.setState(state => {
				const jobTypes = state.jobTypes.concat(value)
				return {
					jobTypes
				}
			},
				() => this.props.handleDataChanged(true))
		} else if (checked === false) {
			this.setState(state => {
				const jobTypes = state.jobTypes.filter(job => value !== job)
				return {
					jobTypes
				}
			},
				() => this.props.handleDataChanged(true))
		}
	}

	
	//Handling one input (current scheme)
	renderColumns = () => {
		const { classes } = this.props
		const { size, width, height, prodLength } = this.state
		return this.props.columns.map(column => {
			return (
				<FormControl component="fieldset" key={column.field} className={classes.cell}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={e => this.onSelectProperty(e, column.field)}
									value={this.state[column]}
									checked={this.state[column.field]}
									disabled={checkDisabledColumns(column.field, size, width, height, prodLength)}
								/>
							}
							label={column.title}
						/>
					</FormGroup>
				</FormControl>
			)
		})
	}

	renderJobs = () => {
		const { t, classes } = this.props
		const jobTypes = [
			{field: 'Businesscard', title: t('settings.paperSizes.businesscard')},
			{field: 'Print', title: t('settings.paperSizes.print') },
			{field: 'Largeprint', title: t('settings.paperSizes.largeprint') },
			{field: 'Magazine', title: t('settings.paperSizes.magazine') },
			{field: 'Book', title: t('settings.paperSizes.book') },
			{field: 'File', title: t('settings.paperSizes.file') },
			{field: 'Design', title: t('settings.paperSizes.design') },
			{field: 'Product', title: t('settings.paperSizes.product') },
			{field: 'Mailing', title: t('settings.paperSizes.mailing') },
			{field: 'Binding', title: t('settings.paperSizes.binding')}
		]

		return jobTypes.map(job => {
			return (
				<FormControl
					component="fieldset"
					key={job.field}
					className={classes.cell}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.jobTypes.filter(selectedJob => selectedJob === job.field).length === 1}
									onChange={this.onSelectJob}
									value={job.field}
									disabled={checkDisabledJobs(job.field, this.state.jobTypes)}
								/>
							}
							label={job.title}
						/>
					</FormGroup>
				</FormControl>
			)
		})
	}

	renderStorageSettings = () => {
		return this.props.storageSettings.map(property => {
			return (
				<FormControl component="fieldset" key={property.field}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={e => this.onSelectProperty(e, property.field)}
									value={this.state[property]}
									checked={this.state[property.field]}
								/>
							}
							label={property.title}
						/>
					</FormGroup>
				</FormControl>
			)
		})
	}

	cancel = () => {
		this.setState({
			isDeleteModalOpen: false
		})
	}

	handleDelete = () => {
		this.setState({
			isDeleteModalOpen: true
		})
	}

	onDelete = async () => {
		try {
			let payload = {
				groupId: this.state.groupId
			}

			const response = await fetchApi('/api/groups/deleteGroup', 'POST', payload)
				if (response.status === 200) {
					await this.props.loadCategories("deleted")
					this.props.handleClose()
				}
				if (response.status === 204) {
					console.log('status 204')
					return null
				}
		} catch (err) {
			console.error(err.message)
			return err.message
		}
	}

	render() {
		const { t } = this.props

		return (
			<div className="EditCategory">
				<h1 className="modal-h1">{t('storage.editCategory')}</h1>

				<TextField
					required
					style={{ minWidth: 400, maxWidth: '50%' }}
					error={this.state.errorGroupName}
					helperText={this.state.helperText}
					label={t('storage.categoryName')}
					variant="outlined"
					type="text"
					name="add-group"
					value={this.state.nameCat}
					onChange={this.onEditCategoryName}
				/>

				<div className="margin-top-20">
					{this.renderStorageSettings()}
				</div>

				<div className="wrapper">
					<div className="title">
							{t('storage.setGroupProperties')}
					</div>
					{this.renderColumns()}
				</div>

				<div className="wrapper">
					<div className="title">
						{t('storage.productsUsedForJobs')}
					</div>
					{this.renderJobs()}
				</div>

				<DeleteHandler
					modalVisible={this.state.isDeleteModalOpen}
					closeModal={this.cancel}
					cancel={this.cancel}
					accept={this.onDelete}
					confirmationQuestion={t('storage.deleteCategory')}
					warningMessage={t('storage.willDeleteCategory')}
				/>

				<div className="modal-buttons">
					<div
						className="btn-33 goback-button"
						onClick={this.props.handleCancel}
					>
						<div className="button-icon">
							<CancelIcon />
						</div>
						<div className="button-text">{t('orders.cancel')}</div>
					</div>

					<div
						className="btn-33 remove-button"
						onClick={this.handleDelete}
					>
						<div className="button-icon">
							<DeleteIcon />
						</div>
						<div className="button-text">{t('orders.delete')}</div>
					</div>

					<div
						className="btn-33 edit-button"
						onClick={this.onEdit}>
						<div className="button-icon">
							<SaveIcon />
						</div>
						<div className="button-text">{t('orders.save')}</div>
					</div>
				</div>

			</div>
		)
	}
}
export default withRouter(withTranslation()(withStyles(styles)(EditCategory)))
