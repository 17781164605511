import { SET_PRICELIST_DATA } from '../../actions/actionTypes'

const initialState = {
	itemArray: {},
	priceList: []
}

const ReducerPricelist = (state = initialState, action) => {
	switch (action.type) {
		case SET_PRICELIST_DATA: {
			return {
				...state,
				pricelist: action.payload
			}
		}
		default:
			return state
	}
}

export default ReducerPricelist
