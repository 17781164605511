// Action types
export const SET_USERDATA = 'SET_USERDATA'
export const SET_CUSTOMERDATA = 'SET_CUSTOMERDATA'
export const HANDLE_SEARCH = 'HANDLE_SEARCH'
export const COUNTS_CALC = 'COUNTS_CALC'
export const CHANGE_CUSTOMERFIELD = 'CHANGE_CUSTOMERFIELD'
export const MANAGE_CLICKS = 'MANAGE_CLICKS'
export const GET_CLIENT = 'GET_CLIENT'
export const SHOW_COMMENT = 'SHOW_COMMENT'
export const CHANGE_FIELD = 'CHANGE_FIELD'
export const RESET_TIME = 'RESET_TIME'
export const CHANGE_TIMECARD_FIELD = 'CHANGE_TIMECARD_FIELD'
export const CHANGE_RECORDING_FIELD = 'CHANGE_RECORDING_FIELD'
export const CHANGE_WORKORDER_FIELD = 'CHANGE_WORKORDER_FIELD'
export const SET_ORDER_DATA = 'SET_ORDER_DATA'
export const SET_PRICELIST_DATA = 'SET_PRICELIST_DATA'
export const CHANGE_FIELD_ADDCUSTOMER = 'CHANGE_FIELD_ADDCUSTOMER'
export const SET_AS_DEFAULT_ADDCUSTOMER = 'SET_AS_DEFAULT_ADDCUSTOMER'
export const CHANGE_FIELD_EDITINFO = 'CHANGE_FIELD_EDITINFO'
export const CHANGE_FIELD_BILLID = 'CHANGE_FIELD_BILLID'
export const CHANGE_ANOTHER_FIELD = 'CHANGE_ANOTHER_FIELD'
