import React from 'react'

import {
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const JobTemplates = ({ onChange, selectedJobs, printingJobs, ...props }) => {
	const { t } = props
	return printingJobs.map((job, index) => {
		return (
			<FormControl component="fieldset" key={index}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								onChange={onChange}
								value={job.name}
								checked={selectedJobs.indexOf(job.name) > -1}
							/>
						}
						label={t(job.localeTitle)}
					/>
				</FormGroup>
			</FormControl>
		)
	})
}
export default withTranslation()(JobTemplates)