export const handleKeypress = e => {
    const characterCode = e.key
    const validArr = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown'
    ]
    if (validArr.includes(characterCode)) return
    // let letterNumber = null
    // if (characterCode === ',') {
    // 	letterNumber = /^[0-9]+[\,?|\.?]$/;
    // }
    // const characterNumber = Number(characterCode)
    if (
        (Number(characterCode) >= 0 && Number(characterCode) <= 9) ||
        characterCode === ',' ||
        characterCode === '.'
    ) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
            return true
        }
    } else {
        e.preventDefault()
    }
}