import { CHANGE_WORKORDER_FIELD } from '../../actions/actionTypes'

const workOrder = {
	body: [
		{
			name: '',
			friendlyId: '',
			employeeId: '',
			customerId: '',
			priority: '',
			status: '',
			deadline: '',
			workAdded: '',
			contentOfWork: '',
			offer: '',
			columns: ''
		}
	],
	check: [],
	data: []
}

const users = {
	body: [
		{
			username: '',
			name: '',
			userEmail: '',
			mobile: '',
			newRoles: ''
		}
	],
	editUserFlag: false,
	userId: null,
}

export const ReducerUsers = (state = users, action) => {
	switch (action.type) {
		case 'CHANGE_BODY_FIELD':
			return {
				...state,
				[action.field]: action.value
			}
		default:
			return state
	}
}

export const ReducerTyonjonohaut = (state = workOrder, action) => {
	switch (action.type) {
		case CHANGE_WORKORDER_FIELD:
			return {
				...state,
				[action.field]: action.value
			}

		default:
			return state
	}
}
