import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import MaterialTable from '@material-table/core'
import { createMuiTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeBody } from '../../actions/Tyonjonohaut-actions'
import { withTranslation } from 'react-i18next'
import './MaterialComTable.css'

class MaterialComTable extends Component {
	render() {
		const { t } = this.props
		const {
			title,
			columns,
			data,
			editable,
			actions,
			opts,
			theme,
			onRowClick,
			components,
			localization,
			icons
		} = this.props

		const tableTheme = createMuiTheme({
			overrides: {
				MuiTableRow: {
					hover: {
						'&:hover': {
							backgroundColor: '#EDEDED !important'
						}
					}
				},
				MuiTableSortLabel: {
					root: {
						'&:hover': {
							color: '#FFF !important'
						},
						color: '#FFF !important'
					},
					active: {
						color: '#FFF !important'
					},
					icon: {
						color: '#FFF !important'
					}
				},
				MuiTableCell: {
					root: {
						borderBottom: '0px solid white',
						padding: '12px'
					},
					body: {
						borderBottom: '1px solid rgba(224, 224, 224, 1)'
					}
				},
				MuiToolbar: {
					regular: {
						height: '32px',
						minHeight: '32px',
						'@media (min-width: 600px)': {
							minHeight: '64px'
						}
					}
				}
			}
		})

		const tableThemeReports = createMuiTheme({
			overrides: {
				MuiTableRow: {
					hover: {
						'&:hover': {
							backgroundColor: '#CCC !important'
						}
					}
				},
				MuiTableSortLabel: {
					root: {
						'&:hover': {
							color: '#FFF !important'
						},
						color: '#FFF !important'
					},
					active: {
						color: '#FFF !important'
					},
					icon: {
						color: '#FFF !important'
					}
				},
				MuiTableCell: {
					root: {
						borderBottom: '0px solid white',
						padding: '12px'
					},
					body: {
						borderBottom: '1px solid rgba(224, 224, 224, 1)'
					}
				},
				MuiToolbar: {
					regular: {
						height: '32px',
						minHeight: '32px',
						'@media (min-width: 600px)': {
							minHeight: '48px'
						}
					}
				}
			}
		})
		switch (theme) {
			case 'orderQueue':
			case 'offers':
			case 'orders': {
				return (
					<div className="MaterialComTable">
						<ThemeProvider theme={tableTheme}>
							<MaterialTable
								options={opts || ''}
								title={title}
								columns={columns}
								data={data}
								editable={editable}
								actions={actions}
								components={components}
								style={{ width: '100%' }}
								onRowClick={(index, value) =>
									this.props.history.push(`/orders/editorder/${value.orderId}`)
								}
								localization={{
									body: {
										emptyDataSourceMessage: `${t('storage.noDataToDisplay')}`,
										filterRow: {
											filterTooltip: `${t('orders.search')}`,
											filterPlaceHolder: `${t('orders.search')}`
										}
									}
								}}
								icons={icons}
							/>
						</ThemeProvider>
					</div>
				)
			}
			case 'users': {
				return (
					<div className="MaterialComTable">
						<ThemeProvider theme={tableTheme}>
							<MaterialTable
								options={opts || ''}
								title={title}
								columns={columns}
								data={data}
								editable={editable}
								actions={actions}
								components={components}
								onRowClick={(index, value) => {
									this.props.changeBody('editUserFlag', true)
									this.props.changeBody('userId', value.userId)
								}}
								localization={{
									body: {
										emptyDataSourceMessage: `${t('storage.noDataToDisplay')}`
									}
								}}
							/>
						</ThemeProvider>
					</div>
				)
			}
			case 'billing': {
				return (
					<div className="MaterialComTable">
						<ThemeProvider theme={tableTheme}>
							<MaterialTable
								options={opts || ''}
								title={title}
								columns={columns}
								data={data}
								editable={editable}
								actions={actions}
								components={components}
								style={{ width: '100%' }}
								onRowClick={(index, value) =>
									value.typeRecord === 'order'
										? this.props.history.push(
												`/orders/editorder/${value.orderId}`
										  )
										: // : this.props.history.push(`/bills/bills/${value.orderId}`)
										  this.props.onInvoiceRowClick(value.orderId)
								}
								localization={{
									body: {
										emptyDataSourceMessage: `${t('storage.noDataToDisplay')}`,
										filterRow: {
											filterTooltip: `${t('orders.search')}`,
											filterPlaceHolder: `${t('orders.search')}`
										}
									}
								}}
								icons={icons}
							/>
						</ThemeProvider>
					</div>
				)
			}
			case 'storage': {
				return (
					<div className="MaterialComTable">
						<ThemeProvider theme={tableTheme}>
							<MaterialTable
								onRowClick={onRowClick}
								options={opts || ''}
								title={title}
								columns={columns}
								data={data}
								editable={editable}
								actions={actions}
								components={components}
								localization={localization}
								style={{ borderRadius: 10 }}
							/>
						</ThemeProvider>
					</div>
				)
			}
			case 'reports': {
				const { t } = this.props

				return (
					<div className="MaterialComTable">
						<ThemeProvider theme={tableThemeReports}>
							<MaterialTable
								options={opts || ''}
								title={title}
								columns={columns}
								data={data}
								editable={editable}
								actions={actions}
								components={components}
								localization={{
									toolbar: {
										searchPlaceholder: `${t('orders.search')}`
									},
									body: {
										emptyDataSourceMessage: `${t('storage.noDataToDisplay')}`,
										filterRow: {
											filterTooltip: `${t('orders.search')}`,
											filterPlaceHolder: `${t('orders.search')}`
										}
									},
									header: {
										actions: ''
									}
								}}
								icons={icons}
							/>
						</ThemeProvider>
					</div>
				)
			}
			default: {
				return (
					<div>
						<MaterialTable
							options={{
								search: false
							}}
							title={title}
							columns={columns}
							data={data}
							editable={editable}
							actions={actions}
						/>
					</div>
				)
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		users: state.users
	}
}

const mapStateToDispatch = dispatch => {
	return {
		changeBody: (field, value) => {
			dispatch(changeBody(field, value))
		}
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapStateToDispatch
	)(withTranslation()(MaterialComTable))
)
