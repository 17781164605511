import React, { Component } from 'react'
import { fetchApi } from '../../../services/api'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import JobTemplates from './JobTemplates'
import {
    Button,
    Grid,
    TextField,
    Typography,
    FormControl,
} from '@material-ui/core'

const StyledTextField = withStyles({
    root: {
        margin: '10px 0',
        backgroundColor: '#FFF'
    }
})(TextField)

const StyledGrid = withStyles({
    /*root: {}*/
})(Grid)

const StyledGridSizes = withStyles({
    root: {
        alignItems: 'center',
        paddingBottom: 20
    }
})(Grid)

const StyledGridItem = withStyles({
    root: {
        paddingRight: 20,
        alignItems: 'center'
    }
})(Grid)

class AddCustomSize extends Component {
    constructor(props) {
        super(props)
        this.state = {
            namePaperSize: '',
            widthPaperSize: 0,
            heightPaperSize: 0,
            selectedJobsAdding: this.props.selectedJobs || [],
            addingSize: false,
            errorSizeName: false
        }
    }

    onAddingSelectJob = (e) => {
        const { value, checked } = e.target
        if (checked === true) {
            this.setState(state => {
                const selectedJobsAdding = state.selectedJobsAdding.concat(value)
                return {
                    selectedJobsAdding,
                    addingSize: true,
                }
            })
        } else if (checked === false) {
            this.setState(state => {
                const selectedJobsAdding = state.selectedJobsAdding.filter(job => value !== job)
                return {
                    selectedJobsAdding,
                    addingSize: true,
                }
            })
        }
        this.props.onAddingSize(true)
    }

    handleInputChange = async e => {
        const target = e.target
        const value = target.value
        const name = target.name
        let errorSizeName = this.state.errorSizeName
        if (name === 'namePaperSize') {
            errorSizeName = false
        }
        await new Promise(resolve => {
            this.setState(
                {
                    [name]: value,
                    addingSize: true,
                    errorSizeName
                },
                () => resolve()
            )
        })
        this.props.onAddingSize(true)
    }

    onSubmit = async e => {
        e.preventDefault()
        const { namePaperSize, widthPaperSize, heightPaperSize, selectedJobsAdding } = this.state
        const { customSizes } = this.props

        const customSizeNames = customSizes.map(size => {
            if (typeof size.value !== 'undefined')
                return size.value.name
            else return size.name
        })

        if (customSizeNames.includes(namePaperSize)) {
            this.setState({
                errorSizeName: true
            })
        } else {
            try {
                const payload = {
                    name: namePaperSize,
                    width: widthPaperSize,
                    height: heightPaperSize,
                    // added temporarely, to fill the data, must be removed after the functionality for custom sizes is completed
                    // group: idGroup,
                    jobTypes: selectedJobsAdding
                }

                const response = await fetchApi(
                    `/api/v1/companies/companies/customsizes`,
                    'POST',
                    payload
                )

                if (response.status === 200) {
                    this.setState({
                        namePaperSize: '',
                        widthPaperSize: '',
                        heightPaperSize: '',
                        selectedJobsAdding: [],
                        addingSize: false,
                    })
                    this.props.onLoadCompanySettings().then(resolve => {
                        this.props.createPrintingJobsArray()
                    })
                    this.props.onAddingSize(false)
                } else {
                    console.log("Error", response)
                }

            } catch (err) {
                console.error(err.message)
            }
        }
    }

    onCancel = () => {
        this.setState({
            namePaperSize: '',
            widthPaperSize: 0,
            heightPaperSize: 0,
            selectedJobsAdding: [],
            addingSize: false,
            errorSizeName: false
        })
        this.props.onAddingSize(false)
    }

    render() {
        const {
            namePaperSize,
            widthPaperSize,
            heightPaperSize,
            addingSize,
        } = this.state

        const { t } = this.props
        return (
            <>
                <form onSubmit={this.onSubmit}>
                    <FormControl required fullWidth>
                        <StyledGrid>
                            <StyledGrid container direction="row" alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6" color="primary">{t('settings.addNewSize')}</Typography>
                                </Grid>
                                {addingSize && (
                                    <StyledGrid container direction="row" justify="flex-end" item xs>
                                        <StyledGridItem item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="small"
                                            >
                                                {t('settings.add')}
                                            </Button>
                                        </StyledGridItem>
                                        <StyledGrid item>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                type="button"
                                                onClick={this.onCancel}
                                            >
                                                {t('settings.clear')}
                                            </Button>
                                        </StyledGrid>

                                    </StyledGrid>
                                )}
                            </StyledGrid>
                            <StyledGridSizes container direction="row" spacing={2}>
                                <StyledGrid
                                    item
                                    xs={4}
                                    style={{ marginTop: this.state.errorSizeName && 19 }}
                                >
                                    <StyledTextField
                                        variant="outlined"
                                        fullWidth
                                        label={t('settings.paperSize')}
                                        name="namePaperSize"
                                        value={namePaperSize || ''}
                                        required
                                        onChange={this.handleInputChange}
                                        error={this.state.errorSizeName}
                                        helperText={this.state.errorSizeName ? t('settings.duplicateSizeName') : ''}
                                    />
                                </StyledGrid>

                                <StyledGrid
                                    item
                                    xs={2}
                                >
                                    <StyledTextField
                                        variant="outlined"
                                        fullWidth
                                        label={t('settings.width')}
                                        name="widthPaperSize"
                                        value={widthPaperSize || ''}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </StyledGrid>

                                <StyledGrid
                                    item
                                    xs={2}
                                // style={{ marginRight: '10px' }}
                                >
                                    <StyledTextField
                                        variant="outlined"
                                        fullWidth
                                        label={t('settings.height')}
                                        name="heightPaperSize"
                                        value={heightPaperSize || ''}
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                </StyledGrid>
                            </StyledGridSizes>
                            <StyledGridSizes>
                                <StyledGrid container direction="column">
                                    <StyledGrid container item xs alignItems="flex-start">
                                        <Typography variant="body1">
                                            {t('settings.specifySizes')}
                                        </Typography>
                                    </StyledGrid>
                                    <StyledGrid container direction="row" item xs >
                                        <JobTemplates
                                            onChange={this.onAddingSelectJob}
                                            selectedJobs={this.state.selectedJobsAdding}
                                            printingJobs={this.props.printingJobs}
                                        />
                                    </StyledGrid>
                                </StyledGrid>
                            </StyledGridSizes>
                        </StyledGrid>

                    </FormControl>
                </form>
            </>
        )
    }
}

export default withRouter(withTranslation()(AddCustomSize))
