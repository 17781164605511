import React from 'react'
import './dialogs.css'

import {
    Grid,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const PageAmountConfirmation = ({ accept, cancel, print, pages, defaultPageAmount }) => {
    const { t } = useTranslation()
    const pageAmount = pages === '' ? t('orders.undefined') : pages
    return (
        <Grid>
            <Grid container item style={{ marginBottom: 15 }}>
                <Typography variant="h5" style={{ color: "#d11a2a" }}>{t('orders.error')} </Typography>
                <Typography variant='h5'>
                    {` ${t('orders.pageAmountIs')} `} {typeof pageAmount === 'undefined' ? t('orders.undefined') :  pageAmount }
                    {` ${t('orders.printingIs')} ${print}.`}
                </Typography>
            </Grid>
            <Grid container item style={{ marginBottom: 15 }}>
            <Typography variant='body1'>
                    {`${t('orders.printWarning')} ${defaultPageAmount}.`}
                </Typography>
            </Grid>
            <Grid container item style={{ marginBottom: 15 }}>
                <Typography variant="h6">
                    {`${t('orders.changePages')} ${defaultPageAmount} ?`}
                </Typography>
            </Grid>
            <div className="modal-buttons">
				<div className="btn-33 goback-button" onClick={cancel}>
					<div className="button-text">{t('orders.no')}</div>
				</div>
				<div className="btn-33 edit-button" onClick={accept}>
					<div className="button-text">{t('orders.yes')}</div>
				</div>
			</div>
        </Grid>
    )
}
export default PageAmountConfirmation