import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import i18n from '../../i18n';

import clsx from 'clsx'
import {
	makeStyles,
	withStyles
} from '@material-ui/core/styles'

import { withTranslation } from 'react-i18next'

import {
	List,
	Button,
	Grid,
	Divider,
	IconButton,
	ListItem,
	Collapse,
	Select,
	MenuItem,
	Tooltip
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@material-ui/icons/Person'
import TimerIcon from '@material-ui/icons/Timer'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import WorkIcon from '@material-ui/icons/Work'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AssessmentIcon from '@material-ui/icons/Assessment'
import HelpIcon from '@material-ui/icons/Help'
import SettingsIcon from '@material-ui/icons/Settings'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import './HeaderBar.css'

class HeaderBar extends Component {

	constructor(props) {
		super(props)
		this.state = {
			menuOpen: false,
			openAdminPanel: false,
			openTimeCard: false,
			openReports: false
		}
	}

	closeMobile = () => {
		if (window.innerWidth < 719 && this.state.menuOpen === true) {
			this.setState({menuOpen: false})
			document.body.style.overflowY = 'visible'
		}
	}

	toggleMenu = () => {
		if (window.innerWidth < 719 && this.state.menuOpen === false) {
			document.body.style.overflowY = 'hidden'
		} else {
			document.body.style.overflowY = 'visible'
		}

		if (window.innerWidth >= 719 && this.state.menuOpen === true) {
			this.setState({
				openAdminPanel: false,
				openTimecard: false,
				openReports: false
			})
		}

		this.setState({menuOpen: !this.state.menuOpen})
	}

	handleOpenTimecard = () => {
		this.setState({
			openAdminPanel: false,
			openTimecard: !this.state.openTimecard,
			openReports: false
		})
	}

	handleOpenReports = () => {
		this.setState({
			openAdminPanel: false,
			openTimecard: false,
			openReports: !this.state.openReports
		})
	}

	handleOpenAdminPanel = () => {
		this.setState({
			openAdminPanel: !this.state.openAdminPanel,
			openTimecard: false,
			openReports: false
		})
	}

	handleDrawerOpen = () => {
		this.setState({
			menuOpen: true,
			openAdminPanel: false,
			openTimecard: false,
			openReports: false
		})
	}

	handleDrawerClose = () => {
		this.setState({
			menuOpen: false,
			openAdminPanel: false,
			openTimecard: false,
			openReports: false
		})
	}

	changeLanguage = (lng, e) => {
		e.preventDefault()
		i18n.changeLanguage(lng)
			.then()
		if (
			window.location.pathname === '/orders/orderqueue' ||
			window.location.pathname === '/storage/warehousemanagement' ||
			window.location.pathname === '/billing/billable' ||
			window.location.pathname === '/reports/ledgers'
		) {
			window.location.reload()
		}
	}

	componentDidMount() {
		if (window.innerWidth >= 719) {
			this.setState({menuOpen: true})
		}
	}

	render() {

		const useStyles = makeStyles(theme => ({
			menuButton: {
				marginRight: 15
			},
			nested: {
				paddingLeft: theme.spacing(4)
			}
		}))

		const StyledSelect = withStyles({
			icon: {
				color: '#FFF'
			},
			root: {
				color: '#FFF',
				paddingTop: 6,
				paddingBottom: 6
			}
		})(Select)

		const StyledMenuItem = withStyles({
			root: {
				paddingTop: 0,
				paddingBottom: 0
			}
		})(MenuItem)

		const StyledListItemText = withStyles({
			root: {
				marginTop: 0,
				marginBottom: 0,
				paddingTop: 0,
				paddingBottom: 0,
				textAlign: 'center'
			}
		})(ListItemText)


		const props = this.props

	const { t } = this.props

	const classes = useStyles

	const isActiveList = value =>
		props.location.pathname === value
			? {	backgroundColor: '#333', color: '#FFF' }
			: { color: '#333' }

	const isActiveIcon = value =>
		props.location.pathname === value
			? { backgroundColor: '#333', color: '#FFF' }
			: { color: '#333' }

	const isActiveText = value =>
		props.location.pathname === value
			? { backgroundColor: '#333', color: '#FFF' }
			: { color: '#333' }

	const isActive = value =>
		props.location.pathname === value
			? { backgroundColor: '#333', color: '#FFF' }
			: { color: '#333' }

	const active = value =>
		props.location.pathname === value
			? " active"
			: " inactive"

	const path = props.location.pathname.split('/')[1]

	let availablePages = []
	if (JSON.parse(localStorage.getItem('userData')) !== null) {
		if (JSON.parse(localStorage.getItem('availablePages')) !== null) {
			availablePages = JSON.parse(localStorage.getItem('availablePages'))
		}
	}

	if (path !== 'pdf') {
		return (
			<div className="HeaderBar">
				{props.userInfo && (
					<Grid className="hide-print">
						<div className="navbar">
							<div className="navbar-wrapper">

								{/* Menu Icon */}
								<IconButton
									color="inherit"
									aria-label="open menu"
									onClick={this.toggleMenu}
									edge="start"
									className={clsx(classes.menuButton, {})}
								>
									<MenuIcon/>
								</IconButton>

								{/* Logo */}
								<Link to="/" onClick={() => this.closeMobile()}>
									<img src="/kuvat/RuleLogo2022.svg" height="50" alt="logo"/>
								</Link>

								{/* Language selections */}
								<Grid container justify="flex-end" alignItems="center">
									<StyledSelect
										defaultValue={t('defaultLang')}
										variant="outlined"
										style={{
											marginRight: 10,
											border: '1px solid #FFF'
										}}
									>

										<StyledMenuItem value="FI" disableGutters={true}>
											<StyledListItemText
												primary="FI"
												onClick={e => this.changeLanguage('fi', e)}
											>
												{t('defaultLang')}
											</StyledListItemText>
										</StyledMenuItem>

										<StyledMenuItem value="EN" disableGutters={true}>
											<StyledListItemText
												primary="EN"
												onClick={e => this.changeLanguage('en', e)}
											>
												{t('defaultLang')}
											</StyledListItemText>
										</StyledMenuItem>

										{/*<StyledMenuItem value="SWE" disableGutters={true}>
											<StyledListItemText
												primary="SWE"
												onClick={e => changeLanguage('swe', e)}
											>
												{t('defaultLang')}
											</StyledListItemText>
										</StyledMenuItem>*/}

									</StyledSelect>

									<Button
										className="logout-button"
										variant="contained"
										onClick={props.onLogout}
									>
										<ExitToAppIcon className="exit-icon"/>
										{t('logout')}
									</Button>
								</Grid>
							</div>
						</div>

						{/* NAV ITEMS */}
						<div className={this.state.menuOpen ? "menu-wide" : "menu-narrow"}>

							<Divider/>

							{/* User Nav */}
							<List>
								<li
									className={props.path === 'users' ? 'selected_item' : ''}
									onClick={() => props.addActive(6)}
								>
									<Link
										to={`/settings/users/usercustom/${props.userInfo.objectId}`}
										onClick={() => this.closeMobile()}>

										{this.state.menuOpen === true ? (
											<ListItem
												className="menu-item person-wrapper"
												key="users"
												button
											>
												<PersonIcon className="person-icon"/>
												<span>
											{props.userInfo && props.userInfo.username
												? props.userInfo.username
												: ''}
										</span>
											</ListItem>
										)

										:

										(
											<ListItem
												className="menu-item"
												button
												key="avatar">
												<Tooltip
													title={`${t('nav.user')}${props.userInfo.username}`}
													placement="top-end"
												>
													<ListItemIcon style={{ color: '#333' }}>
														<PersonIcon/>
													</ListItemIcon>
												</Tooltip>
												<ListItemText style={{ color: 'rgba(0,0,0,0.87)' }}>

											<span>
												{props.userInfo && props.userInfo.username
													? props.userInfo.username
													: ''}
											</span>
												</ListItemText>
											</ListItem>
										)}

									</Link>
								</li>
							</List>

							<Divider/>

							<List>
								{/* Home Page Nav */}
								{
									<>
										<Link to="/" onClick={() => this.closeMobile()}>
											<ListItem
												className={"menu-item" + active('/')}
												button
												key="adminPanel"
											>
												<Tooltip title={t('nav.home')} placement="top-end">
													<ListItemIcon className={active('/')}>
														<HomeIcon/>
													</ListItemIcon>
												</Tooltip>

												<ListItemText
													className={active('/')}
													primary={t('nav.home')}
												/>
											</ListItem>
										</Link>
									</>
								}

								{/* Admin Panel Nav */}
								{(JSON.parse(localStorage.getItem('userData')).role === 'superadmin'
									&& JSON.parse(localStorage.getItem('userData')).userId === 'aXt1KeVwblFdVj1CW9LYHL8U3LFrdHHY') ? (
									<>
										<Link to="/admin/panel" onClick={() => this.closeMobile()}>
											<ListItem
												className="menu-item"
												button
												key="adminPanel"
												style={isActiveList('/admin/panel')}
											>
												<Tooltip title={t('nav.adminPanel')} placement="top-end">
													<ListItemIcon style={isActiveIcon('/admin/panel')}>
														<PeopleAltIcon/>
													</ListItemIcon>
												</Tooltip>

												<ListItemText
													style={isActiveText('/admin/panel')}
													primary={t('nav.adminPanel')}
												/>
											</ListItem>
										</Link>
									</>
								) : ''}

								{/* Timecard Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('timecard') ? (
									<>
										<Link to="/timecard/record" onClick={() => this.closeMobile()}>
											<ListItem
												className="menu-item"
												button
												key="timecard"
												style={isActiveList('/timecard/record')}
											>
												<Tooltip title={t('nav.timecard')} placement="top-end">
													<ListItemIcon style={isActiveIcon('/timecard/record')}>
														<TimerIcon/>
													</ListItemIcon>
												</Tooltip>

												<ListItemText
													style={isActiveText('/timecard/record')}
													primary={t('nav.timecard')}
												/>
												{this.state.openTimecard ?
													<ExpandLess onClick={this.handleOpenTimecard}/> :
													<ExpandMore onClick={this.handleOpenTimecard}/>
												}
											</ListItem>
										</Link>

										<Collapse in={this.state.openTimecard} timeout="auto">
											<List component="div" disablePadding>

												{/* Timecard Hours Subnav */}
												<Link to="/timecard/hourspecification" onClick={() => this.closeMobile()}>
													<ListItem
														button
														className={classes.nested + " menu-sub-item"}
														key="hourspecification"
														style={isActive('/timecard/hourspecification')}
													>
														<ListItemIcon
															style={isActive('/timecard/hourspecification')}
														>
															<ChevronRightIcon/>
														</ListItemIcon>
														<ListItemText
															style={isActive('/timecard/hourspecification')}
															primary={t('nav.hourspec')}
														/>
													</ListItem>
												</Link>

												{/* Timecard Hours Report Subnav */}
												<Link to="/timecard/hourlyreport">
													<ListItem
														button
														className={classes.nested + " menu-sub-item"}
														key="hourlyreport"
														style={isActive('/timecard/hourlyreport')}
													>
														<ListItemIcon
															style={isActive('/timecard/hourlyreport')}
														>
															<ChevronRightIcon/>
														</ListItemIcon>
														<ListItemText
															style={isActive('/timecard/hourlyreport')}
															primary={t('nav.hourly')}
														/>
													</ListItem>
												</Link>

											</List>
										</Collapse>
									</>
								) : null}

								{/* Clients Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('clients') ?
									<Link to="/clients/customerinfo" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="clients"
											style={isActiveList('/clients/customerinfo')}
										>
											<Tooltip title={t('nav.clients')} placement="top-end">
												<ListItemIcon style={isActiveIcon('/clients/customerinfo')}>
													<PeopleAltIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/clients/customerinfo')}
												primary={t('nav.clients')}
											/>
										</ListItem>
									</Link>
									: null}

								{/* Orders Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('orders') ? (
									<Link to="/orders/orderqueue" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="orders"
											style={isActiveList('/orders/orderqueue')}
										>
											<Tooltip title={t('nav.orders')} placement="top-end">
												<ListItemIcon style={isActiveIcon('/orders/orderqueue')}>
													<WorkIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/orders/orderqueue')}
												primary={t('nav.orders')}
											/>
										</ListItem>
									</Link>
								) : null}

								{/* Invoices Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('invoices') ?
									<Link to="/billing/billable" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="billing"
											style={isActive('/billing/billable')}
										>
											<Tooltip title={t('nav.invoices')} placement="top-end">
												<ListItemIcon style={isActive('/billing/billable')}>
													<CreditCardIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/billing/billable')}
												primary={t('nav.invoices')}
											/>
										</ListItem>
									</Link>
									: null}

								{/* Strorage Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('storage') ?
									<Link to="/storage/warehousemanagement" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="storage"
											style={isActive('/storage/warehousemanagement')}
										>
											<Tooltip title={t('nav.storage')} placement="top-end">
												<ListItemIcon style={isActive('/storage/warehousemanagement')}>
													<AllInboxIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/storage/warehousemanagement')}
												primary={t('nav.storage')}
											/>
										</ListItem>
									</Link>
									: null}

								{/* Reports Nav */}
								{availablePages.length !== 0 &&
								availablePages.includes('reports') ?
									<Link to="/reports/ledgers" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="reports"
											style={isActiveList('/reports/ledgers')}
										>
											<Tooltip title={t('nav.reports')} placement="top-end">
												<ListItemIcon style={isActiveIcon('/reports/ledgers')}>
													<AssessmentIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/reports/ledgers')}
												primary={t('nav.reports')}
											/>
										</ListItem>
									</Link>
								: null}

								{/* Guide Nav */}
								<Link to="/guide" onClick={() => this.closeMobile()}>
									<ListItem
										className="menu-item"
										button
										key="guide"
										style={isActiveList('/guide')}
									>
										<Tooltip title={t('nav.reports')} placement="top-end">
											<ListItemIcon style={isActiveIcon('/guide')}>
												<HelpIcon/>
											</ListItemIcon>
										</Tooltip>

										<ListItemText
											style={isActiveText('/guide')}
											primary={"Ohje"}
										/>
									</ListItem>
								</Link>
							</List>

							<Divider/>

							<List>
								{availablePages.length !== 0 &&
								availablePages.includes('settings') ?
									<Link to="/settings/company" onClick={() => this.closeMobile()}>
										<ListItem
											className="menu-item"
											button
											key="settings"
											style={isActiveList('/settings/company')}
										>
											<Tooltip title={t('nav.settings')} placement="top-end">
												<ListItemIcon style={isActiveIcon('/settings/company')}>
													<SettingsIcon/>
												</ListItemIcon>
											</Tooltip>

											<ListItemText
												style={isActiveText('/settings/company')}
												primary={t('nav.settings')}
											/>
										</ListItem>
									</Link>
									: null}
							</List>
					</div>
					</Grid>
				)}

				{/* PAGE CONTENT */}
				<main>
					{props.children}
				</main>
			</div>
		)
	} else {
		return (
			<>
				{props.children}
			</>
		)
	}
}
}

export default withRouter(withTranslation()(HeaderBar))
