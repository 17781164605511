import axios from 'axios';

export const fetchApi = async (
	ENDPOINT,
	METHOD = 'GET',
	PAYLOAD = {},
	HEADERS
) => {
	try {
		let BASEURL = process.env.REACT_APP_SERVER_ADDRESS;
		let URL = BASEURL + ENDPOINT;
		let header = {};
		if (localStorage.getItem('userData')) {
			header = {
				Authorization:
					'token ' + JSON.parse(localStorage.getItem('userData')).sessionId,
			}
		}

		const options = {};
		options.method = METHOD;
		options.headers = HEADERS ? HEADERS : header;
		options.url = URL;
		options.validateStatus = status => {
			return status <= 599;
		}

		if (METHOD === 'GET') {
			options.params = PAYLOAD;
		} else {
			options.data = PAYLOAD;
		}

		const res = await axios(options);
		const { data } = res;

		if (data.code === 401) {
			localStorage.removeItem('userData');
			window.location.href = '/login';
			return false;
		} else {
			return new Promise(function(resolve, reject) {
				resolve(res);
			});
		}
	} catch (err) {
		console.log(err);
	}
}
